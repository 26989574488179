import { useState, useEffect, useCallback, useRef } from "react";
import { Link } from "react-router-dom";
import "./app-bar.css";
import { useTranslation } from "react-i18next";
import LanguageToggleButton from "../language-toggle-button";

const longLogo = "/logos/mops_yesil-beyaz-logo.svg";
const shortLogo = "/logos/mops_brandmark-renkli.svg";

const AppBar = () => {
  const { t: translate, i18n } = useTranslation();
  const [logo, setLogo] = useState(longLogo);
  const [isModelsMenuExpanded, setIsModelsMenuExpanded] = useState(false);
  const modelsMenuRef = useRef(null);
  const modelsNavItemRef = useRef(null);
  const hamburgerRef = useRef(null);
  const hamburgerMenuRef = useRef(null);
  const hamburgerModelsMenuRef = useRef(null);
  const hamburgerModelsNavItemRef = useRef(null);
  const [isHamburgerMenuOpen, setIsHamburgerMenuOpen] = useState(false);

  // useEffect(() => {
  //   window.onscroll = function () {
  //     scrollFunction();
  //   };

  //   function scrollFunction() {
  //     const scrollAmount = document.documentElement.scrollTop;
  //     if (scrollAmount > 80) {
  //       document.getElementById("appbar").style.opacity = 1;
  //     } else {
  //       document.getElementById("appbar").style.opacity = 0.6;
  //     }
  //   }
  // }, []);

  const changeLanguage = (language) => {
    i18n.changeLanguage(language);
  };

  const toggleModelsMenu = () => {
    setIsModelsMenuExpanded((isModelsMenuExpanded) => !isModelsMenuExpanded);
  };

  const toggleHamburgerMenu = () => {
    setIsHamburgerMenuOpen((isHamburgerMenuOpen) => !isHamburgerMenuOpen);
  };

  const setCorrectLogo = useCallback(() => {
    if (window.matchMedia("(max-width: 400px)").matches) setLogo(shortLogo);
    else setLogo(longLogo);
  }, []);

  const closeMenusOnResize = useCallback(() => {
    setIsHamburgerMenuOpen(false);
    setIsModelsMenuExpanded(false);
  }, []);

  const closeHamburgerMenuOnClickedOutside = useCallback(
    (e) => {
      if (
        hamburgerMenuRef.current &&
        isHamburgerMenuOpen &&
        !hamburgerMenuRef.current.contains(e.target) &&
        hamburgerRef.current &&
        !hamburgerRef.current.contains(e.target)
      ) {
        setIsHamburgerMenuOpen(false);
      }
    },
    [isHamburgerMenuOpen]
  );
  const closeModelsMenuOnClickedOutside = useCallback(
    (e) => {
      if (
        modelsMenuRef.current &&
        hamburgerModelsMenuRef.current &&
        isModelsMenuExpanded &&
        !modelsMenuRef.current.contains(e.target) &&
        !hamburgerModelsMenuRef.current.contains(e.target) &&
        modelsNavItemRef.current &&
        hamburgerModelsNavItemRef.current &&
        !modelsNavItemRef.current.contains(e.target) &&
        !hamburgerModelsNavItemRef.current.contains(e.target)
      ) {
        setIsModelsMenuExpanded(false);
      }
    },
    [isModelsMenuExpanded]
  );

  useEffect(() => {
    window.addEventListener("resize", setCorrectLogo);
    window.addEventListener("resize", closeMenusOnResize);
    window.addEventListener("mousedown", closeHamburgerMenuOnClickedOutside);
    window.addEventListener("mousedown", closeModelsMenuOnClickedOutside);
  }, [
    setCorrectLogo,
    closeMenusOnResize,
    closeHamburgerMenuOnClickedOutside,
    closeModelsMenuOnClickedOutside,
  ]);

  return (
    <header>
      <nav
        id="appbar"
        className="appbar | background-color-primary-1000 box-shadow-sm"
      >
        <div className="appbar-content-wrapper | vertical-align-center h6-font subtitle1-font-sm-only padding-inline-24 padding-inline-16-sm-only">
          <div
            className="appbar-hamburger-container"
            onClick={toggleHamburgerMenu}
            ref={hamburgerRef}
          >
            <img
              className="appbar-hamburger"
              src={`${
                isHamburgerMenuOpen
                  ? "/icons/hamburger-open.svg"
                  : "/icons/hamburger.svg"
              }`}
            />
          </div>
          <Link className="appbar-logo-container" to="/">
            <img className="appbar-logo" src={logo} />
          </Link>
          <ul className="nav">
            <li className="nav-item">
              <Link
                className="nav-item-link | text-color-white-87"
                to="/about-us"
              >
                <p>{translate("about-us")}</p>
              </Link>
            </li>
            <li
              className="nav-item"
              data-dropdown={true}
              onClick={toggleModelsMenu}
              ref={modelsNavItemRef}
            >
              <Link
                className="nav-item-link | text-color-white-87"
                // data-dropdown={true}
                to="/models"
              >
                <p>{translate("models")}</p>
                {/* <img
                  className="models-menu-arrow"
                  src={`${
                    isModelsMenuExpanded
                      ? "/icons/expand-less.svg"
                      : "/icons/expand-more.svg"
                  }`}
                /> */}
                {/* container is just for onMouseOver and onMouseLeave reasons */}
                {/* <div
                  className="nav-item-link-dropdown-container | background-color-primary-850 box-shadow-sm padding-block-16"
                  data-expanded={isModelsMenuExpanded}
                  ref={modelsMenuRef}
                >
                  <div className="nav-item-link-dropdown">
                    <span className="nav-item-link-dropdown-header | b1-font transparent-font">
                      {translate("trailer")}
                    </span>
                    <Link
                      className="nav-item-link-dropdown-item | h6-font subtitle1-font-sm-only text-color-white-87"
                      to="/model"
                    >
                      Model 1
                    </Link>
                    <Link
                      className="nav-item-link-dropdown-item | h6-font subtitle1-font-sm-only text-color-white-87"
                      to="/model"
                    >
                      Model 2
                    </Link>
                    <Link
                      className="nav-item-link-dropdown-item | h6-font subtitle1-font-sm-only text-color-white-87"
                      to="/gallery"
                    >
                      {translate("gallery")}
                    </Link>

                    <span className="nav-item-link-dropdown-header | b1-font transparent-font margin-top-16">
                      {translate("RV")}
                    </span>
                    <Link
                      className="nav-item-link-dropdown-item | h6-font subtitle1-font-sm-only text-color-white-87"
                      to="/model"
                    >
                      Model 1
                    </Link>
                  </div>
                </div> */}
              </Link>
            </li>
            <li className="nav-item" id="appbar-nav-item-gallery">
              <Link
                className="nav-item-link | text-color-white-87"
                to="/gallery"
              >
                <p>{translate("gallery")}</p>
              </Link>
            </li>
            <li className="nav-item" id="appbar-nav-item-pricing">
              <Link
                className="nav-item-link | text-color-white-87"
                to="/pricing"
              >
                <p>{translate("pricing")}</p>
              </Link>
            </li>
            {/* <li className="nav-item" id="appbar-nav-item-blog">
              <Link className="nav-item-link | text-color-white-87" to="/blog">
                <p>{translate("blog")}</p>
              </Link>
            </li> */}
            <li className="nav-item">
              <Link
                className="nav-item-link | text-color-white-87"
                to="/contact"
              >
                <p>{translate("contact")}</p>
              </Link>
            </li>
          </ul>
          <div id="language-toggle-wrapper" className="justify-self-end">
            <LanguageToggleButton changeLanguage={changeLanguage} i18n={i18n} />
          </div>
        </div>
        <div
          className="hamburger-menu-container | background-color-primary-1000 padding-inline-24 padding-inline-16-sm-only padding-top-8 padding-bottom-32 box-shadow-md"
          data-expanded={isHamburgerMenuOpen}
          ref={hamburgerMenuRef}
        >
          <div className="hamburger-menu">
            <Link
              className="hamburger-menu-item | h6-font text-color-white-87"
              to="/about-us"
              onClick={() => setIsHamburgerMenuOpen(false)}
            >
              {translate("about-us")}
            </Link>
            {/* <div
              className="hamburger-menu-item-accordion-container"
              ref={hamburgerModelsMenuRef}
            >
              <div
                className="hamburger-menu-item | h6-font text-color-white-87"
                data-accordion={true}
                onClick={toggleModelsMenu}
                ref={hamburgerModelsNavItemRef}
              >
                <p>{translate("models")}</p>
                <img
                  className="models-menu-arrow"
                  src={`${
                    isModelsMenuExpanded
                      ? "/icons/expand-less.svg"
                      : "/icons/expand-more.svg"
                  }`}
                />
              </div>
              <div
                className="hamburger-menu-item-accordion | padding-block-8"
                data-expanded={isModelsMenuExpanded}
              >
                <span className="hamburger-menu-item-accordion-header | b1-font transparent-font">
                  {translate("trailer")}
                </span>
                <Link
                  className="hamburger-menu-item-accordion-item | h6-font text-color-white-87 mx-16"
                  to="/model"
                  onClick={() => setIsHamburgerMenuOpen(false)}
                >
                  Model 1
                </Link>
                <Link
                  className="hamburger-menu-item-accordion-item | h6-font text-color-white-87 mx-16"
                  to="/model"
                  onClick={() => setIsHamburgerMenuOpen(false)}
                >
                  Model 2
                </Link>
                <Link
                  className="hamburger-menu-item-accordion-item | h6-font text-color-white-87 mx-16"
                  to="/gallery"
                  onClick={() => setIsHamburgerMenuOpen(false)}
                >
                  {translate("gallery")}
                </Link>

                <span className="hamburger-menu-item-accordion-header | b1-font transparent-font margin-top-8">
                  {translate("RV")}
                </span>
                <Link
                  className="hamburger-menu-item-accordion-item | h6-font text-color-white-87 mx-16"
                  to="/model"
                  onClick={() => setIsHamburgerMenuOpen(false)}
                >
                  Model 1
                </Link>
              </div>
            </div> */}
            <Link
              className="hamburger-menu-item | h6-font text-color-white-87"
              to="/models"
              onClick={() => setIsHamburgerMenuOpen(false)}
            >
              {translate("models")}
            </Link>
            <Link
              className="hamburger-menu-item | h6-font text-color-white-87"
              to="/gallery"
              onClick={() => setIsHamburgerMenuOpen(false)}
            >
              {translate("gallery")}
            </Link>
            <Link
              className="hamburger-menu-item | h6-font text-color-white-87"
              to="/pricing"
              onClick={() => setIsHamburgerMenuOpen(false)}
            >
              {translate("pricing")}
            </Link>
            {/* <Link
              className="hamburger-menu-item | h6-font text-color-white-87"
              to="/blog"
              onClick={() => setIsHamburgerMenuOpen(false)}
            >
              {translate("blog")}
            </Link> */}
            <Link
              className="hamburger-menu-item | h6-font text-color-white-87"
              to="/contact"
              onClick={() => setIsHamburgerMenuOpen(false)}
            >
              {translate("contact")}
            </Link>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default AppBar;
