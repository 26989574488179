import http from "../http-common";

class ImageMetaDataService {
  getImageMeta(models, imageTypes, qualities) {
    let modelsFilter = models.map((model, index) =>
      index === 0 ? `model=${model}` : `&model=${model}`
    );
    modelsFilter = modelsFilter.toString().replaceAll(",", "");

    let imageTypesFilter = imageTypes.map(
      (imageType) => `&imageType=${imageType}`
    );
    imageTypesFilter = imageTypesFilter.toString().replace(",", "");

    let qualityFilter = qualities.map((quality) => `&quality=${quality}`);
    imageTypesFilter = imageTypesFilter.toString().replace(",", "");

    return http.get(
      `/imageMeta?${modelsFilter}${imageTypesFilter}${qualityFilter}`
    );
  }
}

export default new ImageMetaDataService();
