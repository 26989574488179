import "./advanced-button.css";
import { Link } from "react-router-dom";

const AdvancedButton = (props) => {
  const { classes, primaryText, icon, secondaryText, to, isDisabled } = props;
  return (
    <Link
      to={to}
      className={`advanced-button | text-decoration-none `}
      role="button"
    >
      <div
        className={`advanced-button-content-container | padding-inline-16 padding-block-16 ${classes}`}
      >
        {icon && (
          <div className="advanced-button-icon-container">
            <img className="advanced-button-icon" src={icon} />
          </div>
        )}
        <div className="advanced-button-texts-container">
          {primaryText && (
            <p className="advanced-button-primary-text | h6-font">
              {primaryText}
            </p>
          )}
          {secondaryText && (
            <p className="advanced-button-secondary-text | caption-font text-color-white-60">
              {secondaryText}
            </p>
          )}
        </div>
      </div>
      <div className="advanced-button-overlay" />
    </Link>
  );
};

export default AdvancedButton;
