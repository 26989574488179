import "./button.css";
import { Link } from "react-router-dom";

const Button = (props) => {
  const {
    classes,
    innerClasses,
    text,
    leftIcon,
    rightIcon,
    isPrimary = false,
    isTransparent,
    onClick,
    to,
  } = props;

  return (
    <Link
      to={to}
      onClick={onClick}
      className={`button text-decoration-none `}
      role="button"
    >
      <div
        className={`button-content-container | 
        ${leftIcon ? "padding-left-16" : "padding-left-24"} 
        ${rightIcon ? "padding-right-16" : "padding-right-24"} 
        box-shadow-sm ${classes}`}
        data-primary={isPrimary}
        data-transparent={isTransparent ? isTransparent : false}
      >
        {leftIcon && (
          <div className="button-left-icon-container">
            <img
              className="button-left-icon"
              src={leftIcon}
              alt="Button left icon"
            />
          </div>
        )}

        <p className="button-text | button-font text-uppercase">{text}</p>

        {rightIcon && (
          <div className="button-right-icon-container">
            <img
              className="button-right-icon"
              src={rightIcon}
              alt="Button right icon"
            />
          </div>
        )}
      </div>
      <div className="button-overlay" />
    </Link>
  );
};

export default Button;
