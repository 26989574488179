import "./content-list.css";
import { useState, useEffect, useRef, useCallback } from "react";
import { useTranslation } from "react-i18next";

const ContentList = (props) => {
  const {
    classes,
    pageTitles,
    pageContentId,
    horizontalPositionRefId,
    verticalPositionRefId,
  } = props;
  const { t: translate } = useTranslation();

  const [activeTitleIndex, setActiveTitleIndex] = useState(null);
  const [activeSubTitleIndex, setActiveSubTitleIndex] = useState(null);
  const [isSmallScreen, setIsSmallScreen] = useState(
    window.matchMedia("(max-width: 900px)").matches
  );

  const [contentListRailHeight, setContentListRailHeight] = useState(null);
  const [contentListLeftPosition, setContentListLeftPosition] = useState(null);
  const [contentListTopPosition, setContentListTopPosition] = useState(null);

  const isActiveStateChangeDisabled = useRef(0);

  const handleResize = useCallback(() => {
    if (document.getElementById(pageContentId)) {
      setContentListRailHeight(
        document.getElementById(pageContentId).clientHeight
      );
    }
    if (
      horizontalPositionRefId &&
      document.getElementById(horizontalPositionRefId)
    ) {
      const pageContentOffsets = document
        .getElementById(horizontalPositionRefId)
        .getBoundingClientRect();
      setContentListLeftPosition(pageContentOffsets.right);
    } else {
      setContentListLeftPosition(null);
    }
    if (
      verticalPositionRefId &&
      document.getElementById(verticalPositionRefId)
    ) {
      const pageContentOffsets = document.getElementById(
        verticalPositionRefId
      ).clientHeight;
      setContentListTopPosition(pageContentOffsets + 48);
    } else {
      setContentListTopPosition(null);
    }
  }, [pageContentId, horizontalPositionRefId]);

  const checkIfSmallScreen = useCallback(() => {
    if (window.matchMedia("(max-width: 900px)").matches) setIsSmallScreen(true);
    else setIsSmallScreen(false);
  });

  useEffect(() => {
    handleResize();
    checkIfSmallScreen();
    window.addEventListener("resize", checkIfSmallScreen);
    window.addEventListener("resize", handleResize);
  }, [handleResize, checkIfSmallScreen]);

  const getIndexOfParentTitle = useCallback((pageTitles, subOf) => {
    let parentTitleIndex = -1;
    for (const [index, title] of Object.keys(pageTitles).entries()) {
      if (title.localeCompare(subOf) === 0) {
        parentTitleIndex = index;
        break;
      }
    }

    return parentTitleIndex;
  }, []);

  const handleActiveTitleChange = useCallback(
    (titleIndex, subOf, pageTitles, isByClick) => {
      if (subOf) {
        setActiveSubTitleIndex(titleIndex);
        setActiveTitleIndex(getIndexOfParentTitle(pageTitles, subOf));
      } else {
        setActiveTitleIndex(titleIndex);
        setActiveSubTitleIndex(null);
      }
      if (isByClick) {
        isActiveStateChangeDisabled.current = true;
        setTimeout(function () {
          isActiveStateChangeDisabled.current = false;
        }, 1000);
      }
    },
    [setActiveSubTitleIndex, setActiveTitleIndex, getIndexOfParentTitle]
  );

  const scrollHandler = useCallback(
    (pageTitles) => {
      let isPageTitlesUptoDate = Object.keys(pageTitles).map((title) => {
        if (document.getElementById(title)) return true;
        else return false;
      });

      if (isPageTitlesUptoDate.every((element) => element === true)) {
        let scrollPosition = window.scrollY + 240;

        let sectionDistances = Object.keys(pageTitles).map((title) => {
          let section = document.getElementById(title);
          let sectionPosition = section.offsetTop + section.offsetHeight;
          let sectionDistance = sectionPosition - scrollPosition;
          return { title, distance: sectionDistance };
        });

        let minDistance = 99999;
        let minDistanceTitle = "";
        let minDistanceIndex = -1;

        for (const [index, section] of sectionDistances.entries()) {
          if (section.distance < minDistance && section.distance > 0) {
            minDistance = section.distance;
            minDistanceTitle = section.title;
            minDistanceIndex = index;
          }
        }

        if (minDistanceIndex !== -1 && !isActiveStateChangeDisabled.current)
          handleActiveTitleChange(
            minDistanceIndex,
            pageTitles[minDistanceTitle].subOf,
            pageTitles
          );
      }
    },
    [handleActiveTitleChange]
  );

  useEffect(() => {
    window.addEventListener("scroll", () => scrollHandler(pageTitles));
  }, [pageTitles, scrollHandler]);

  return (
    <div
      className={`content-list-rail ${classes}`}
      style={
        isSmallScreen
          ? {
              marginLeft: "var(--container-padding)",
            }
          : {
              position: "absolute",
              left: contentListLeftPosition + "px",
              height: contentListRailHeight,
            }
      }
    >
      <nav
        className={`content-list ${classes}`}
        style={{ top: contentListTopPosition + "px" }}
      >
        {Object.keys(pageTitles).map((title, index) => (
          <a
            href={`#${title}`}
            key={`content-list-title-${index}`}
            className={`content-list-any-title
            ${
              pageTitles[title].subOf
                ? "content-list-sub-title"
                : "content-list-title"
            }
          | subtitle1-font subtitle2-font-sm-only padding-top-8`}
            onClick={() =>
              handleActiveTitleChange(
                index,
                pageTitles[title].subOf,
                pageTitles,
                true
              )
            }
            data-active={
              index === activeTitleIndex || index === activeSubTitleIndex
                ? true
                : false
            }
            data-anchor={true}
          >
            {translate(title)}
          </a>
        ))}
      </nav>
    </div>
  );
};

export default ContentList;
