import "./model.css";
import { useTranslation } from "react-i18next";
import FeaturesSection from "../../components/features-section";
import SubFeaturesSection from "../../components/sub-features-section";
import TextLeftImagesRight from "../../components/text-left-images-right";
import ImagesLeftTextRight from "../../components/images-left-text-right";
import ImagesTopTextsBottom from "../../components/images-top-texts-bottom";
import GallerySection from "../../components/gallery-section";
import TitleDescriptionContent from "../../components/title-description-content";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import SimpleTable from "../../components/simple-table";
import ContentList from "../../components/content-list/content-list";
import { useState, useEffect, useCallback } from "react";
import { modelDetails } from "../../definitions/model-details";
import { useSearchParams } from "react-router-dom";
import ImageMetaDataService from "../../services/images";
import { imageTypeKeys } from "../../definitions/image-types";
import { cacheImages, isImagesInCache } from "../../utils/cache-images";
import { modelKeys } from "../../definitions/models";
import { imageQualities } from "../../definitions/image-qualities";

const Model = () => {
  let [searchParams, setSearchParams] = useSearchParams();
  const modelSearchParam = searchParams.get("model");
  const { t: translate } = useTranslation();
  const pageTitles = {
    // "teaser-video": {},
    // highlights: {},
    "general-features": {},
    "model-dimensions": { subOf: "general-features" },
    layouts: { subOf: "general-features" },
    "equipment-details": {},
    furniture: { subOf: "equipment-details" },
    bathroom: { subOf: "equipment-details" },
    lighting: { subOf: "equipment-details" },
    "control-panel": { subOf: "equipment-details" },
    "gas-system": { subOf: "equipment-details" },
    "window-system": { subOf: "equipment-details" },
    "solar-energy-system": { subOf: "equipment-details" },
    "heating-system": { subOf: "equipment-details" },
    "water-tank-and-plumming": { subOf: "equipment-details" },
    "exterior-equipments": { subOf: "equipment-details" },
    body: { subOf: "equipment-details" },
    chassis: { subOf: "equipment-details" },
    "rubber-rim": { subOf: "equipment-details" },
    // "living-space": { subOf: "equipment-details" },
    // kitchen: { subOf: "equipment-details" },
    // bedroom: { subOf: "equipment-details" },
    // storage: { subOf: "equipment-details" },
    // ventilation: { subOf: "equipment-details" },
    // "design-your-caravan": {},
    options: {},
    gallery: {},
  };

  const containerClassesNarrow =
    "container-narrow container-narrow-md container-narrow-sm padding-block-64 padding-block-48-md-only padding-block-32-sm-only";
  const carouselSectionContainerClassesNarrow =
    "container-narrow container-narrow-md container-narrow-sm padding-block-48 padding-block-32-md-only padding-block-24-sm-only";
  const containerClasses =
    "container padding-block-64 padding-block-48-md-only padding-block-32-sm-only";

  const [
    contentListhorizontalPositionRefId,
    setContentListhorizontalPositionRefId,
  ] = useState(null);

  const [activeHighlightCardIndex, setActiveHighlightCardIndex] = useState(0);

  const [modelGalleryImages, setModelGalleryImages] = useState([]);
  const [modelHeroImage, setModelHeroImage] = useState(undefined);
  const [modelDimensionsImages, setModelDimensionsImages] = useState(undefined);
  const [layoutsImages, setLayoutsImages] = useState(undefined);
  const [furnitureImages, setFurnitureImages] = useState(undefined);
  const [bathroomImages, setBathroomImages] = useState(undefined);
  const [lightingImages, setLightingImages] = useState(undefined);
  const [controlPanelImages, setControlPanelImages] = useState(undefined);
  const [gasSystemImages, setGasSystemImages] = useState(undefined);
  const [windowSystemImages, setWindowSystemImages] = useState(undefined);
  const [solarEnergySystemImages, setSolarEnergySystemImages] =
    useState(undefined);
  const [heatingSystemImages, setHeatingSystemImages] = useState(undefined);
  const [waterTankAndPlummingImages, setWaterTankAndPlummingImages] =
    useState(undefined);
  const [exteriorEquipmentsImages, setExteriorEquipmentsImages] =
    useState(undefined);
  const [bodyImages, setBodyImages] = useState(undefined);
  const [chassisImages, setChassisImages] = useState(undefined);
  const [rubberRimImages, setRubberRimImages] = useState(undefined);

  const [activeImageIndex, setActiveImageIndex] = useState(0);

  const checkContentListhorizontalPositionRefId = useCallback(() => {
    if (window.matchMedia("(max-width: 1120px)").matches)
      setContentListhorizontalPositionRefId("appbar-nav-item-pricing");
    else setContentListhorizontalPositionRefId("appbar-nav-item-pricing");
  }, []);

  useEffect(() => {
    checkContentListhorizontalPositionRefId();
    window.addEventListener("resize", checkContentListhorizontalPositionRefId);
  }, [checkContentListhorizontalPositionRefId]);

  //******************* */

  const callModelHeroBackgroundImage = useCallback(async (modelSearchParam) => {
    let lowQualityHeroImage, highQualityHeroImage;
    if (window.matchMedia("(max-width: 600px)").matches) {
      lowQualityHeroImage =
        modelDetails[modelSearchParam].heroImages.sm.lowQuality;
      highQualityHeroImage =
        modelDetails[modelSearchParam].heroImages.sm.highQuality;
    } else if (window.matchMedia("(max-width: 1140px)").matches) {
      lowQualityHeroImage =
        modelDetails[modelSearchParam].heroImages.md.lowQuality;
      highQualityHeroImage =
        modelDetails[modelSearchParam].heroImages.md.highQuality;
    } else {
      lowQualityHeroImage =
        modelDetails[modelSearchParam].heroImages.lg.lowQuality;
      highQualityHeroImage =
        modelDetails[modelSearchParam].heroImages.lg.highQuality;
    }
    const modelHeroSection = document.querySelector("#model-hero-section");
    if (modelHeroSection) {
      modelHeroSection.style.setProperty(
        "--model-hero-section-image-url",
        `url(${lowQualityHeroImage})`
      );
      if (
        !isImagesInCache([lowQualityHeroImage]) &&
        !isImagesInCache([highQualityHeroImage])
      )
        setModelHeroImage(lowQualityHeroImage);

      if (!isImagesInCache([highQualityHeroImage])) {
        await cacheImages([highQualityHeroImage]);
      }
      modelHeroSection.style.setProperty(
        "--model-hero-section-image-url",
        `url(${highQualityHeroImage})`
      );
      setModelHeroImage(highQualityHeroImage);
    }
  }, []);
  const callModelDimensionsImages = useCallback(async (modelSearchParam) => {
    const lowQualityImages =
      modelDetails[modelSearchParam].modelDimensions.images.lowQuality;
    const highQualityImages =
      modelDetails[modelSearchParam].modelDimensions.images.highQuality;

    if (
      !isImagesInCache([lowQualityImages]) &&
      !isImagesInCache([highQualityImages])
    )
      setModelDimensionsImages(lowQualityImages);

    if (!isImagesInCache([highQualityImages]))
      await cacheImages(highQualityImages);
    setModelDimensionsImages(highQualityImages);
  }, []);

  const callLayoutImages = useCallback(async (modelSearchParam) => {
    const highQualityImages =
      modelDetails[modelSearchParam].layouts.images.highQuality;
    if (!isImagesInCache(highQualityImages))
      await cacheImages(highQualityImages);
    setLayoutsImages(highQualityImages);
  }, []);

  const callFurnitureImages = useCallback(async (modelSearchParam) => {
    const lowQualityImages =
      modelDetails[modelSearchParam].equipmentDetails.furniture.images
        .lowQuality;
    const highQualityImages =
      modelDetails[modelSearchParam].equipmentDetails.furniture.images
        .highQuality;
    if (
      !isImagesInCache([lowQualityImages]) &&
      !isImagesInCache([highQualityImages])
    )
      setFurnitureImages(lowQualityImages);

    if (!isImagesInCache([highQualityImages]))
      await cacheImages(highQualityImages);
    setFurnitureImages(highQualityImages);
  }, []);

  const callBathroomImages = useCallback(async (modelSearchParam) => {
    const lowQualityImages =
      modelDetails[modelSearchParam].equipmentDetails.bathroom.images
        .lowQuality;
    const highQualityImages =
      modelDetails[modelSearchParam].equipmentDetails.bathroom.images
        .highQuality;
    if (
      !isImagesInCache([lowQualityImages]) &&
      !isImagesInCache([highQualityImages])
    )
      setBathroomImages(lowQualityImages);
    if (!isImagesInCache([highQualityImages]))
      await cacheImages(highQualityImages);
    setBathroomImages(highQualityImages);
  }, []);

  const callLightingImages = useCallback(async (modelSearchParam) => {
    const lowQualityImages =
      modelDetails[modelSearchParam].equipmentDetails.lighting.images
        .lowQuality;
    const highQualityImages =
      modelDetails[modelSearchParam].equipmentDetails.lighting.images
        .highQuality;
    if (
      !isImagesInCache([lowQualityImages]) &&
      !isImagesInCache([highQualityImages])
    )
      setLightingImages(lowQualityImages);
    if (!isImagesInCache([highQualityImages]))
      await cacheImages(highQualityImages);
    setLightingImages(highQualityImages);
  }, []);

  const callControlPanelImages = useCallback(async (modelSearchParam) => {
    const lowQualityImages =
      modelDetails[modelSearchParam].equipmentDetails["control-panel"].images
        .lowQuality;
    const highQualityImages =
      modelDetails[modelSearchParam].equipmentDetails["control-panel"].images
        .highQuality;
    if (
      !isImagesInCache([lowQualityImages]) &&
      !isImagesInCache([highQualityImages])
    )
      setControlPanelImages(lowQualityImages);
    if (!isImagesInCache([highQualityImages]))
      await cacheImages(highQualityImages);
    setControlPanelImages(highQualityImages);
  }, []);

  const callGasSystemImages = useCallback(async (modelSearchParam) => {
    const lowQualityImages =
      modelDetails[modelSearchParam].equipmentDetails["gas-system"].images
        .lowQuality;
    const highQualityImages =
      modelDetails[modelSearchParam].equipmentDetails["gas-system"].images
        .highQuality;
    if (
      !isImagesInCache([lowQualityImages]) &&
      !isImagesInCache([highQualityImages])
    )
      setGasSystemImages(lowQualityImages);

    if (!isImagesInCache(highQualityImages))
      await cacheImages(highQualityImages);
    setGasSystemImages(highQualityImages);
  }, []);

  const callWindowSystemImages = useCallback(async (modelSearchParam) => {
    const lowQualityImages =
      modelDetails[modelSearchParam].equipmentDetails["window-system"].images
        .lowQuality;
    const highQualityImages =
      modelDetails[modelSearchParam].equipmentDetails["window-system"].images
        .highQuality;
    if (
      !isImagesInCache([lowQualityImages]) &&
      !isImagesInCache([highQualityImages])
    )
      setWindowSystemImages(lowQualityImages);

    if (!isImagesInCache(highQualityImages))
      await cacheImages(highQualityImages);
    setWindowSystemImages(highQualityImages);
  }, []);

  const callSolarEnergySystemImages = useCallback(async (modelSearchParam) => {
    const lowQualityImages =
      modelDetails[modelSearchParam].equipmentDetails["solar-energy-system"]
        .images.lowQuality;
    const highQualityImages =
      modelDetails[modelSearchParam].equipmentDetails["solar-energy-system"]
        .images.highQuality;
    if (
      !isImagesInCache([lowQualityImages]) &&
      !isImagesInCache([highQualityImages])
    )
      setSolarEnergySystemImages(lowQualityImages);

    if (!isImagesInCache(highQualityImages))
      await cacheImages(highQualityImages);
    setSolarEnergySystemImages(highQualityImages);
  }, []);

  const callHeatingSystemImages = useCallback(async (modelSearchParam) => {
    const lowQualityImages =
      modelDetails[modelSearchParam].equipmentDetails["heating-system"].images
        .lowQuality;
    const highQualityImages =
      modelDetails[modelSearchParam].equipmentDetails["heating-system"].images
        .highQuality;
    if (
      !isImagesInCache([lowQualityImages]) &&
      !isImagesInCache([highQualityImages])
    )
      setHeatingSystemImages(lowQualityImages);

    if (!isImagesInCache(highQualityImages))
      await cacheImages(highQualityImages);
    setHeatingSystemImages(highQualityImages);
  }, []);

  const callWaterTankAndPlummingImages = useCallback(
    async (modelSearchParam) => {
      const lowQualityImages =
        modelDetails[modelSearchParam].equipmentDetails[
          "water-tank-and-plumming"
        ].images.lowQuality;
      const highQualityImages =
        modelDetails[modelSearchParam].equipmentDetails[
          "water-tank-and-plumming"
        ].images.highQuality;
      if (
        !isImagesInCache([lowQualityImages]) &&
        !isImagesInCache([highQualityImages])
      )
        setWaterTankAndPlummingImages(lowQualityImages);

      if (!isImagesInCache(highQualityImages))
        await cacheImages(highQualityImages);
      setWaterTankAndPlummingImages(highQualityImages);
    },
    []
  );

  const callExteriorEquipmentsImages = useCallback(async (modelSearchParam) => {
    const lowQualityImages =
      modelDetails[modelSearchParam].equipmentDetails["exterior-equipments"]
        .images.lowQuality;
    const highQualityImages =
      modelDetails[modelSearchParam].equipmentDetails["exterior-equipments"]
        .images.highQuality;
    if (
      !isImagesInCache([lowQualityImages]) &&
      !isImagesInCache([highQualityImages])
    )
      setExteriorEquipmentsImages(lowQualityImages);

    if (!isImagesInCache(highQualityImages))
      await cacheImages(highQualityImages);
    setExteriorEquipmentsImages(highQualityImages);
  }, []);

  const callBodyImages = useCallback(async (modelSearchParam) => {
    const lowQualityImages =
      modelDetails[modelSearchParam].equipmentDetails.body.images.lowQuality;
    const highQualityImages =
      modelDetails[modelSearchParam].equipmentDetails.body.images.highQuality;
    if (
      !isImagesInCache([lowQualityImages]) &&
      !isImagesInCache([highQualityImages])
    )
      setBodyImages(lowQualityImages);

    if (!isImagesInCache(highQualityImages))
      await cacheImages(highQualityImages);
    setBodyImages(highQualityImages);
  }, []);

  const callChassisImages = useCallback(async (modelSearchParam) => {
    const lowQualityImages =
      modelDetails[modelSearchParam].equipmentDetails.chassis.images.lowQuality;
    const highQualityImages =
      modelDetails[modelSearchParam].equipmentDetails.chassis.images
        .highQuality;
    if (
      !isImagesInCache([lowQualityImages]) &&
      !isImagesInCache([highQualityImages])
    )
      setChassisImages(lowQualityImages);

    if (!isImagesInCache(highQualityImages))
      await cacheImages(highQualityImages);
    setChassisImages(highQualityImages);
  }, []);

  const callRubberRimImages = useCallback(async (modelSearchParam) => {
    const lowQualityImages =
      modelDetails[modelSearchParam].equipmentDetails["rubber-rim"].images
        .lowQuality;
    const highQualityImages =
      modelDetails[modelSearchParam].equipmentDetails["rubber-rim"].images
        .highQuality;
    if (
      !isImagesInCache([lowQualityImages]) &&
      !isImagesInCache([highQualityImages])
    )
      setRubberRimImages(lowQualityImages);

    if (!isImagesInCache(highQualityImages))
      await cacheImages(highQualityImages);
    setRubberRimImages(highQualityImages);
  }, []);

  const callGalleryImages = useCallback(async (modelSearchParam) => {
    const lowQualityGalleryImagesResponse =
      await ImageMetaDataService.getImageMeta(
        [modelKeys[modelSearchParam], "common"],
        [imageTypeKeys["interior-design"], imageTypeKeys["exterior-design"]],
        [imageQualities["low-quality"]]
      );
    const lowQualityGalleryImages =
      lowQualityGalleryImagesResponse.data.imageMetaList;

    const highQualityGalleryImagesResponse =
      await ImageMetaDataService.getImageMeta(
        [modelKeys[modelSearchParam], "common"],
        [imageTypeKeys["interior-design"], imageTypeKeys["exterior-design"]],
        [imageQualities["high-quality"]]
      );
    const highQualityGalleryImages =
      highQualityGalleryImagesResponse.data.imageMetaList;

    if (
      !isImagesInCache([lowQualityGalleryImages]) &&
      !isImagesInCache([highQualityGalleryImages])
    )
      setModelGalleryImages(lowQualityGalleryImages);

    if (!isImagesInCache(highQualityGalleryImages))
      await cacheImages(highQualityGalleryImages);

    setModelGalleryImages(highQualityGalleryImages);
  }, []);

  const setAllImages = async (modelSearchParam) => {
    await callModelHeroBackgroundImage(modelSearchParam);
    await callGalleryImages(modelSearchParam);
    await callModelDimensionsImages(modelSearchParam);
    await callLayoutImages(modelSearchParam);
    await callFurnitureImages(modelSearchParam);
    await callBathroomImages(modelSearchParam);
    await callLightingImages(modelSearchParam);
    await callControlPanelImages(modelSearchParam);
    await callGasSystemImages(modelSearchParam);
    await callWindowSystemImages(modelSearchParam);

    await callSolarEnergySystemImages(modelSearchParam);
    await callHeatingSystemImages(modelSearchParam);
    await callWaterTankAndPlummingImages(modelSearchParam);
    await callExteriorEquipmentsImages(modelSearchParam);
    await callBodyImages(modelSearchParam);
    await callChassisImages(modelSearchParam);
    await callRubberRimImages(modelSearchParam);
    window.addEventListener("resize", () =>
      callModelHeroBackgroundImage(modelSearchParam)
    );
  };

  useEffect(() => {
    modelSearchParam && setAllImages(modelSearchParam);
  }, [modelSearchParam]);

  // useEffect(() => {
  //   const imageModels = [modelSearchParam, "common"];
  //   const imageTypes = [
  //     imageTypeKeys["exterior-design"],
  //     imageTypeKeys["interior-design"],
  //   ];
  //   ImageMetaDataService.getImageMeta(imageModels, imageTypes).then(
  //     (response) => {
  //       setModelGalleryImages(response.data.imageMetaList);
  //     }
  //   );
  // }, [modelSearchParam]);

  /** to scroll into section automatically when visited from another page (like price-list-card > options) */
  useEffect(() => {
    const href = window.location.href.substring(
      window.location.href.lastIndexOf("#") + 1
    );
    const element = document.getElementById(href);
    if (element) {
      element.scrollIntoView();
    } else window.scrollTo(0, -1);
  }, [modelSearchParam]);

  const carouselSettingsNarrow = {
    dots: true,
    arrows: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    initialSlide: 0,
    dotsClass: "model-highlights-carousel-dots",
    beforeChange: (oldIndex, newIndex) => setActiveHighlightCardIndex(newIndex),
    appendDots: (dots) => {
      return (
        <ul>
          {dots.map((item, index) => {
            return (
              <li
                key={index}
                className={
                  activeHighlightCardIndex === index ||
                  (activeHighlightCardIndex > dots.length - 2 &&
                    index === dots.length - 1)
                    ? "slick-active"
                    : ""
                }
              >
                {item.props.children}
              </li>
            );
          })}
        </ul>
      );
    },
    responsive: [
      {
        breakpoint: 1600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <section id="model" className="model">
      <section id="model-hero-section" className="model-hero-section">
        <div
          className={`model-hero-title-container | background-color-gray-60 padding-top-after-appbar box-shadow-sm`}
        >
          <div
            className={`${containerClasses} padding-top-64 padding-top-48-md-only padding-top-32-sm-only padding-bottom-48 padding-bottom-32-md-only`}
          >
            <p
              className={`model-hero-title | h1-font h2-font-md-only h3-font-sm-only`}
            >
              {modelDetails[modelSearchParam].name || ""}
            </p>
          </div>
        </div>
        <div className={containerClassesNarrow}>
          {/* <ModelHeroCard
            text="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam
            tempor augue a libero porttitor varius."
          /> */}
        </div>
      </section>
      <ContentList
        classes="padding-top-48 padding-bottom-24"
        pageTitles={pageTitles}
        pageContentId="model-content"
        horizontalPositionRefId={contentListhorizontalPositionRefId}
        verticalPositionRefId="appbar"
      />
      <section id="model-content" className="model-content">
        {/* <TitleDescriptionContent
          id="teaser-video"
          classes="background-color-primary-1000"
          containerClasses={containerClassesNarrow}
          title={translate("teaser-video")}
        >
          <div>
            <iframe
              className="model-teaser-video-section-video | box-shadow-sm"
              src="https://www.youtube.com/embed/WuvEV-iErv0"
              title="YouTube video player"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
            />
          </div>
        </TitleDescriptionContent> */}
        {/* <TitleDescriptionContent
          id="highlights"
          classes="background-color-primary-800"
          containerClasses={carouselSectionContainerClassesNarrow}
          title={translate("highlights")}
        >
          <Slider
            {...carouselSettingsNarrow}
            className="model-highlights-carousel | margin-top-40"
          >
            <ImageWithText
              image={"/images/stock-caravan.png"}
              secondaryText={"Lorem ipsum dolor sit amet"}
            />
            <ImageWithText
              image={"/images/stock-caravan.png"}
              secondaryText={"Lorem ipsum dolor sit amet"}
            />
            <ImageWithText
              image={"/images/stock-caravan.png"}
              secondaryText={"Lorem ipsum dolor sit amet"}
            />
            <ImageWithText
              image={"/images/stock-caravan.png"}
              secondaryText={"Lorem ipsum dolor sit amet"}
            />
            <ImageWithText
              image={"/images/stock-caravan.png"}
              secondaryText={"Lorem ipsum dolor sit amet"}
            />
          </Slider>
        </TitleDescriptionContent> */}
        <FeaturesSection
          id="general-features"
          classes="background-color-gray-800"
          containerClasses={containerClassesNarrow}
          title={translate("general-features")}
          // text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in."
        >
          <SubFeaturesSection
            id="model-dimensions"
            classes="margin-top-88 margin-top-64-md-only margin-top-48-sm-only"
            title={translate("model-dimensions")}
          >
            <TextLeftImagesRight
              // primaryText="Bir Başlık"
              secondaryTexts={translate(
                `${modelSearchParam}-model-dimensions-secondary-texts`,
                { returnObjects: true }
              )}
              images={modelDimensionsImages}
            />
            {/* <ImagesLeftTextRight
              classes="margin-top-40 margin-top-48-md-only"
              primaryText="Bir Başlık"
              secondaryText="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliqui."
              images={[
                "/images/stock-caravan.png",
                "/images/stock-caravan.png",
              ]}
            /> */}
            {/* <TextLeftImagesRight
              classes="margin-top-40 margin-top-48-md-only"
              primaryText="Bir Başlık"
              secondaryText="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliqui."
              images={["/images/stock-caravan.png"]}
            /> */}
            {/* <ImagesTopTextsBottom
              classes="margin-top-40 margin-top-48-md-only"
              images={[
                "/images/stock-caravan.png",
                "/images/stock-caravan.png",
                "/images/stock-caravan.png",
              ]}
            /> */}
          </SubFeaturesSection>
          <SubFeaturesSection
            id="layouts"
            title={translate("layouts")}
            classes="margin-top-88 margin-top-64-md-only margin-top-48-sm-only"
          >
            <ImagesTopTextsBottom
              images={layoutsImages}
              primaryTexts={modelDetails[modelSearchParam].layouts.primaryTexts}
              secondaryTexts={translate(
                `${modelSearchParam}-layouts-secondary-texts`,
                { returnObjects: true }
              )}
              // buttons={[
              //   <AdvancedButton
              //     classes="margin-top-16 background-color-gray-500 box-shadow-sm"
              //     icon="/icons/360.svg"
              //     primaryText={translate("view-360")}
              //     secondaryText="Plan 1"
              //   />,
              //   <AdvancedButton
              //     classes="margin-top-16 background-color-gray-500 box-shadow-sm"
              //     icon="/icons/360.svg"
              //     primaryText={translate("view-360")}
              //     secondaryText="Plan 2"
              //   />,
              //   <AdvancedButton
              //     classes="margin-top-16 background-color-gray-500 box-shadow-sm"
              //     icon="/icons/360.svg"
              //     primaryText={translate("view-360")}
              //     secondaryText="Plan 3"
              //   />,
              // ]}
            />
          </SubFeaturesSection>
        </FeaturesSection>
        <FeaturesSection
          id="equipment-details"
          classes="background-color-primary-1000"
          title={translate("equipment-details")}
          containerClasses={containerClassesNarrow}
          // text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in."
        >
          <SubFeaturesSection
            id="furniture"
            classes="margin-top-88 margin-top-64-md-only margin-top-48-sm-only"
            title={translate("furniture")}
          >
            <TextLeftImagesRight
              // primaryText="Bir Başlık"
              // secondaryText="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliqui."
              secondaryTexts={translate(
                `${modelSearchParam}-furniture-secondary-texts`,
                { returnObjects: true }
              )}
              images={[(furnitureImages && furnitureImages[0]) || ""]}
            />
            {/* <ImagesLeftTextRight
              classes="margin-top-40"
              primaryText="Bir Başlık"
              secondaryText="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliqui."
              images={[
                "/images/stock-caravan.png",
                "/images/stock-caravan.png",
              ]}
            />
            <TextLeftImagesRight
              classes="margin-top-40"
              primaryText="Bir Başlık"
              secondaryText="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliqui."
              images={["/images/stock-caravan.png"]}
            /> */}
            <ImagesTopTextsBottom
              classes="margin-top-40"
              images={[
                (furnitureImages && furnitureImages[1]) || "",
                (furnitureImages && furnitureImages[2]) || "",
                (furnitureImages && furnitureImages[3]) || "",
              ]}
            />
          </SubFeaturesSection>
          <SubFeaturesSection
            id="bathroom"
            classes="margin-top-88 margin-top-64-md-only margin-top-48-sm-only"
            title={translate("bathroom")}
          >
            <TextLeftImagesRight
              // primaryText="Bir Başlık"
              secondaryTexts={translate(
                `${modelSearchParam}-bathroom-secondary-texts`,
                { returnObjects: true }
              )}
              images={bathroomImages}
            />
            {/* <ImagesTopTextsBottom
              classes="margin-top-40"
              images={[
                "/images/stock-caravan.png",
                "/images/stock-caravan.png",
                "/images/stock-caravan.png",
              ]}
              primaryTexts={["Bir Başlık", "Bir Başlık", "Bir Başlık"]}
              secondaryTexts={[
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliqui.",
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliqui.",
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliqui.",
              ]}
            /> */}
            {/* <ImagesTopTextsBottom
              classes="margin-top-40"
              images={[
                "/images/stock-caravan.png",
                "/images/stock-caravan.png",
              ]}
              primaryTexts={["Bir Başlık", "Bir Başlık"]}
              secondaryTexts={[
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliqui.",
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliqui.",
              ]}
            /> */}
          </SubFeaturesSection>
          {/* <SubFeaturesSection
            id="kitchen"
            classes="margin-top-88 margin-top-64-md-only margin-top-48-sm-only"
            title={translate("kitchen")}
          >
            <TextLeftImagesRight
              primaryText="Bir Başlık"
              secondaryTexts={[
                "Toplam uzunluk: 5100mm",
                "Toplam genişlik: 2120mm",
                "Toplam yükseklik: 2475mm",
                "Kabin uzunluğu: 4070mm",
                "İç yükseklik: 1970mm",
                "İç genişlik: 2040mm",
              ]}
              images={["/images/stock-caravan.png"]}
            />
            <ImagesLeftTextRight
              classes="margin-top-40"
              images={[
                "/images/stock-caravan.png",
                "/images/stock-caravan.png",
              ]}
              primaryText="Bir Başlık"
              secondaryText="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliqui."
            />
            <TextLeftImagesRight
              classes="margin-top-40"
              primaryText="Bir Başlık"
              secondaryTexts={[
                "Toplam uzunluk: 5100mm",
                "Toplam genişlik: 2120mm",
                "Toplam yükseklik: 2475mm",
                "Kabin uzunluğu: 4070mm",
                "İç yükseklik: 1970mm",
                "İç genişlik: 2040mm",
              ]}
              images={["/images/stock-caravan.png"]}
            />
          </SubFeaturesSection> */}

          <SubFeaturesSection
            id="lighting"
            classes="margin-top-88 margin-top-64-md-only margin-top-48-sm-only"
            title={translate("lighting")}
          >
            {/* <TextLeftImagesRight
              // primaryText="Bir Başlık"
              secondaryTexts={[
                "• Dolap Üstü 12 V Spot Aydınlatma",
                "• 12 V Kabin içi Aydınlatma Spotları",
                "• USB Prizler",
              ]}
              images={["/images/stock-caravan.png"]}
            /> */}
            <ImagesLeftTextRight
              classes="margin-top-40"
              // primaryText="Bir Başlık"
              secondaryTexts={translate(
                `${modelSearchParam}-lighting-secondary-texts`,
                { returnObjects: true }
              )}
              images={lightingImages}
            />
            {/* <TextLeftImagesRight
              classes="margin-top-40"
              primaryText="Bir Başlık"
              secondaryTexts={[
                "Toplam uzunluk: 5100mm",
                "Toplam genişlik: 2120mm",
                "Toplam yükseklik: 2475mm",
                "Kabin uzunluğu: 4070mm",
                "İç yükseklik: 1970mm",
                "İç genişlik: 2040mm",
              ]}
              images={["/images/stock-caravan.png"]}
            />
            <ImagesTopTextsBottom
              classes="margin-top-40"
              images={[
                "/images/stock-caravan.png",
                "/images/stock-caravan.png",
                "/images/stock-caravan.png",
              ]}
            /> */}
          </SubFeaturesSection>
          <SubFeaturesSection
            id="control-panel"
            classes="margin-top-88 margin-top-64-md-only margin-top-48-sm-only"
            title={translate("control-panel")}
          >
            <TextLeftImagesRight
              // primaryText="Bir Başlık"
              secondaryTexts={translate(
                `${modelSearchParam}-control-panel-secondary-texts`,
                { returnObjects: true }
              )}
              images={controlPanelImages}
            />
            {/* <ImagesLeftTextRight
              classes="margin-top-40"
              primaryText="İki Başlık"
              secondaryTexts={[
                "• Dolap Üstü 12 V Spot Aydınlatma",
                "• 12 V Kabin içi Aydınlatma Spotları",
                "• USB Prizler",
              ]}
              images={["/images/stock-caravan.png"]}
            />
            <TextLeftImagesRight
              classes="margin-top-40"
              primaryText="Üç Başlık"
              secondaryTexts={[
                "Toplam uzunluk: 5100mm",
                "Toplam genişlik: 2120mm",
                "Toplam yükseklik: 2475mm",
                "Kabin uzunluğu: 4070mm",
                "İç yükseklik: 1970mm",
                "İç genişlik: 2040mm",
              ]}
              images={[
                "/images/stock-caravan.png",
                "/images/stock-caravan.png",
              ]}
            />
            <ImagesTopTextsBottom
              classes="margin-top-40"
              images={[
                "/images/stock-caravan.png",
                "/images/stock-caravan.png",
                "/images/stock-caravan.png",
              ]}
            /> */}
          </SubFeaturesSection>
          <SubFeaturesSection
            id="gas-system"
            classes="margin-top-88 margin-top-64-md-only margin-top-48-sm-only"
            title={translate("gas-system")}
          >
            <ImagesLeftTextRight
              classes="margin-top-40 margin-top-48-md-only"
              // primaryText="Bir Başlık"
              secondaryTexts={translate(
                `${modelSearchParam}-gas-system-secondary-texts`,
                { returnObjects: true }
              )}
              images={gasSystemImages}
            />
          </SubFeaturesSection>
          <SubFeaturesSection
            id="window-system"
            classes="margin-top-88 margin-top-64-md-only margin-top-48-sm-only"
            title={translate("window-system")}
          >
            <TextLeftImagesRight
              // primaryText="Bir Başlık"
              secondaryTexts={translate(
                `${modelSearchParam}-window-system-secondary-texts`,
                { returnObjects: true }
              )}
              images={windowSystemImages}
            />
          </SubFeaturesSection>
          <SubFeaturesSection
            id="solar-energy-system"
            classes="margin-top-88 margin-top-64-md-only margin-top-48-sm-only"
            title={translate("solar-energy-system")}
          >
            <ImagesLeftTextRight
              classes="margin-top-40 margin-top-48-md-only"
              // primaryText="Bir Başlık"
              secondaryTexts={translate(
                `${modelSearchParam}-solar-energy-system-secondary-texts`,
                { returnObjects: true }
              )}
              images={solarEnergySystemImages}
            />
          </SubFeaturesSection>
          <SubFeaturesSection
            id="heating-system"
            classes="margin-top-88 margin-top-64-md-only margin-top-48-sm-only"
            title={translate("heating-system")}
          >
            <TextLeftImagesRight
              // primaryText="Bir Başlık"
              secondaryTexts={translate(
                `${modelSearchParam}-heating-system-secondary-texts`,
                { returnObjects: true }
              )}
              images={heatingSystemImages}
            />
          </SubFeaturesSection>
          <SubFeaturesSection
            id="water-tank-and-plumming"
            classes="margin-top-88 margin-top-64-md-only margin-top-48-sm-only"
            title={translate("water-tank-and-plumming")}
          >
            <ImagesLeftTextRight
              classes="margin-top-40 margin-top-48-md-only"
              // primaryText="Bir Başlık"
              secondaryTexts={translate(
                `${modelSearchParam}-water-tank-and-plumming-secondary-texts`,
                { returnObjects: true }
              )}
              images={waterTankAndPlummingImages}
            />
          </SubFeaturesSection>
          <SubFeaturesSection
            id="exterior-equipments"
            classes="margin-top-88 margin-top-64-md-only margin-top-48-sm-only"
            title={translate("exterior-equipments")}
          >
            <TextLeftImagesRight
              // primaryText="Bir Başlık"
              // secondaryText="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliqui."
              secondaryTexts={translate(
                `${modelSearchParam}-exterior-equipments-secondary-texts`,
                { returnObjects: true }
              )}
              images={exteriorEquipmentsImages}
            />
          </SubFeaturesSection>
          <SubFeaturesSection
            id="body"
            classes="margin-top-88 margin-top-64-md-only margin-top-48-sm-only"
            title={translate("body")}
          >
            <ImagesLeftTextRight
              classes="margin-top-40 margin-top-48-md-only"
              // primaryText="Bir Başlık"
              secondaryTexts={translate(
                `${modelSearchParam}-body-secondary-texts`,
                { returnObjects: true }
              )}
              images={bodyImages}
            />
          </SubFeaturesSection>
          <SubFeaturesSection
            id="chassis"
            classes="margin-top-88 margin-top-64-md-only margin-top-48-sm-only"
            title={translate("chassis")}
          >
            <TextLeftImagesRight
              // primaryText="Bir Başlık"
              secondaryTexts={translate(
                `${modelSearchParam}-chassis-secondary-texts`,
                { returnObjects: true }
              )}
              images={chassisImages}
            />
          </SubFeaturesSection>
          <SubFeaturesSection
            id="rubber-rim"
            classes="margin-top-88 margin-top-64-md-only margin-top-48-sm-only"
            title={translate("rubber-rim")}
          >
            <ImagesLeftTextRight
              classes="margin-top-40 margin-top-48-md-only"
              // primaryText="Bir Başlık"
              secondaryTexts={translate(
                `${modelSearchParam}-rubber-rim-secondary-texts`,
                { returnObjects: true }
              )}
              images={rubberRimImages}
            />
          </SubFeaturesSection>
        </FeaturesSection>
        <TitleDescriptionContent
          id="options"
          classes="background-color-gray-800"
          containerClasses={containerClassesNarrow}
          title={translate("options")}
          // description={[
          //   "Lorem ipsum falan lorem ipsum falan lorem ipsum falan lorem ipsum falan lorem ipsum falan lorem ipsum falan lorem ipsum falan",
          // ]}
        >
          <SimpleTable
            id="model-options-table"
            classes="margin-top-64 margin-top-48-md-only margin-top-32-sm-only"
            titles={[translate("option"), translate("pricing")]}
            rows={translate("option-rows", { returnObjects: true })}
          />
          <div id="options-bottom" />
        </TitleDescriptionContent>
        <TitleDescriptionContent
          id="gallery"
          classes="background-color-gray-800"
          containerClasses={containerClassesNarrow}
          title={translate("gallery")}
        >
          <GallerySection
            id="gallery"
            images={modelGalleryImages}
            activeImageIndex={activeImageIndex}
            changeActiveImageIndex={(index) => {
              setActiveImageIndex(index);
            }}
          />
        </TitleDescriptionContent>
        {/* <TitleDescriptionContent
          id="design-your-caravan"
          classes="background-color-primary-900"
          containerClasses={containerClassesNarrow}
          title={translate("design-your-caravan")}
          description={[
            "İhtiyaçlarına uygun çekme karavanı oluştur, iç tasarımını yap ve opsiyonları seç. Tasarımını bizimle paylaş ve biz seninle iletişime geçelim.",
          ]}
        >
          <DesignPlayground
            classes="margin-top-40"
            layouts={[
              {
                primaryText: "Model XYZ",
                secondaryText: "2 Kişilik",
              },
              {
                primaryText: "Model YZQ",
                secondaryText: "4 Kişilik",
              },
            ]}
            colors={[
              {
                color: "#918988",
                colorName: "Pommery",
              },
              {
                color: "#937D66",
                colorName: "Morgan",
              },
              {
                color: "#4D4843",
                colorName: "Edgar",
              },
              {
                color: "#B4A290",
                colorName: "Matthiase",
              },
              {
                color: "#785456",
                colorName: "Misty Rose",
              },
              {
                color: "#A67C63",
                colorName: "Almond",
              },
            ]}
            otherColors={[
              {
                color: "#4D4843",
                colorName: "Edgar",
              },
              {
                color: "#B4A290",
                colorName: "Matthiase",
              },
              {
                color: "#785456",
                colorName: "Misty Rose",
              },
              {
                color: "#A67C63",
                colorName: "Almond",
              },
            ]}
            image="/images/stock-caravan.png"
          />
        </TitleDescriptionContent> */}
      </section>
    </section>
  );
};

export default Model;
