import "./title-description-content.css";

const TitleDescriptionContent = (props) => {
  const { id, containerClasses, classes, title, description, children, style } =
    props;
  return (
    <section
      id={id}
      className={`title-description-content ${classes}`}
      style={style}
    >
      <div className={containerClasses}>
        <p className="title-description-content-title | h3-font h4-font-md-only">
          {title}
        </p>
        {description &&
          description.map((p) => (
            <p className="title-description-content-description | h6-font b1-font-md-only b2-font-sm-only margin-top-16">
              {p}
            </p>
          ))}
        <div className="title-description-content-content | margin-top-40 margin-top-32-md-only margin-top-24-sm-only">
          {children}
        </div>
      </div>
    </section>
  );
};

export default TitleDescriptionContent;
