import "./images-left-text-right.css";

const ImagesLeftTextRight = (props) => {
  const { primaryText, secondaryTexts, images, classes } = props;
  return (
    <div className={`images-left-text-right ${classes}`}>
      <div
        className={
          images && images.length > 1
            ? "images-left-text-right-images-grid"
            : "images-left-text-right-image-container"
        }
      >
        {images &&
          images.map((image, index) => (
            <img
              key={`images-left-text-right-image-${index}`}
              id={`images-left-text-right-image-${index}`}
              className="images-left-text-right-image | box-shadow-sm"
              src={image || "/icons/mops-favicon.svg"}
            />
          ))}
      </div>
      {/* <div className="images-left-text-right-text | margin-top-48 margin-top-0-md-only"> */}
      <div className="images-left-text-right-text | margin-top-0-md-only">
        <p className="h5-font h6-font-sm-only"> {primaryText} </p>
        <div>
          {secondaryTexts.map((secondaryText, index) => (
            <p
              key={`${secondaryText}-${index}`}
              className="b1-font b2-font-sm-only margin-top-4"
            >
              {secondaryText}
            </p>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ImagesLeftTextRight;
