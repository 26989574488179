import "./text-area.css";
import { useState, useEffect } from "react";

const TextArea = (props) => {
  const { classes, text, placeholderText, onChange, isPrimary, type, name } =
    props;
  const [isFocused, setIsFocused] = useState(false);

  useEffect(() => {
    if (!text) setIsFocused(false);
  }, [text]);

  return (
    <div className="text-area-container">
      <textarea
        type={type ? type : "text"}
        name={name}
        className={`text-area | b1-font padding-inline-24 padding-inline-16-md-only box-shadow-sm ${classes}`}
        data-primary={isPrimary ? isPrimary : false}
        onChange={(e) => onChange(e)}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(text ? true : false)}
        data-focused={isFocused}
        value={text}
      />
      <label
        htmlFor={name}
        className="text-area-placeholder-text"
        data-primary={isPrimary ? isPrimary : false}
        data-focused={isFocused}
      >
        {placeholderText}
      </label>
    </div>
  );
};

export default TextArea;
