import { useEffect } from "react";
import "./page-title-section.css";

const PageTitleSection = (props) => {
  const { title, classes, id, pageTitleImage } = props;

  useEffect(() => {
    const pageTitleSection = document.querySelector(`#${id}`);
    if (pageTitleSection)
      pageTitleSection.style.setProperty(
        "--page-title-image-url",
        `url(${pageTitleImage})`
      );
  }, [pageTitleImage]);
  return (
    <section id={id} className={`page-title-section ${classes}`}>
      <div className="page-title-wrapper | container">
        <p className="page-title | h1-font h2-font-md-only h3-font-sm-only">
          {title}
        </p>
      </div>
    </section>
  );
};

export default PageTitleSection;
