import "./dropdown-button.css";
import { useState } from "react";

const DropdownButton = (props) => {
  const {
    classes,
    primaryText,
    notSelectedText,
    items,
    initialStates,
    onCheck,
  } = props;
  const [itemsSelectedStates, setItemsSelectedStates] = useState(initialStates);

  const [isModelsMenuExpanded, setIsModelsMenuExpanded] = useState(false);
  const toggleModelsMenu = () => {
    setIsModelsMenuExpanded(!isModelsMenuExpanded);
  };

  const toggleCheckbox = (index) => {
    let newStates = itemsSelectedStates;
    newStates[index] = !newStates[index];
    setItemsSelectedStates([...newStates]);
    onCheck([...newStates]);
  };

  return (
    <div
      className={`dropdown-button | text-decoration-none box-shadow-sm ${classes}`}
      role="dropdown-button"
      data-expanded={isModelsMenuExpanded}
    >
      <div
        className={`dropdown-button-upper-part | padding-inline-16 padding-block-6`}
        onClick={toggleModelsMenu}
      >
        <div className="dropdown-button-texts-container">
          <p className="dropdown-button-primary-text | subtitle1-font">
            {primaryText}
          </p>
          <p className="dropdown-button-secondary-text | caption-font transparent-font">
            {itemsSelectedStates.map(
              (state, index) =>
                state &&
                (itemsSelectedStates[index - 1] ? ", " : "") + items[index]
            )}
            {itemsSelectedStates.includes(true) === false && notSelectedText}
          </p>
        </div>
        <div className="dropdown-button-arrow-container">
          <img
            className="dropdown-button-arrow"
            src={
              isModelsMenuExpanded
                ? "/icons/expand-less.svg"
                : "/icons/expand-more.svg"
            }
          />
        </div>
      </div>
      <div className="dropdown-anchor">
        <div
          className="dropdown-button-dropdown | padding-top-8 padding-bottom-12 padding-inline-16"
          data-expanded={isModelsMenuExpanded}
        >
          {items.map((item, index) => (
            <div
              className="dropdown-button-dropdown-item | padding-bottom-6"
              onClick={() => toggleCheckbox(index)}
            >
              <img
                className="dropdown-button-dropdown-item-checkbox"
                src={
                  itemsSelectedStates[index]
                    ? "/icons/checkbox-filled.svg"
                    : "/icons/checkbox-blank.svg"
                }
              />
              <p className="subtitle1-font subtitle1-font-sm-only text-color-white-87">
                {item}
              </p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default DropdownButton;
