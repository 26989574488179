import "./article.css";
import { useLocation } from "react-router-dom";
import PageTitleSection from "../../components/page-title-section";
import { useTranslation } from "react-i18next";

const Article = () => {
  const { t: translate } = useTranslation();
  const { state: article } = useLocation();
  // const [article, setArticle] = useState(null);

  let containerClassesNarrow =
    "container-narrow container-narrow-md container-narrow-sm padding-block-64 padding-block-48-md-only padding-block-32-sm-only";
  return (
    <section className="article | background-color-primary-1000">
      <PageTitleSection
        id="article-page-title-section"
        className="padding-top-after-appbar"
        title={translate(article.articleId)}
      />
      <div className={containerClassesNarrow}>
        <div className="article-content-wrapper">
          <section className="article-prologue-section">
            <p className="article-content-prologue | h4-font h5-font-md-only h6-font-sm-only">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam
              tempor augue a libero porttitor varius. Fusce euismod massa eu
              accumsan ultricies.
            </p>
            <img
              className="article-image | margin-top-40"
              src={process.env.REACT_APP_BACKEND_STATIC + article.image}
              alt={`${article.title}`}
            />
            <img
              className="margin-top-48"
              src="/images/high-quality/dotted-road.svg"
              alt="fancy divider"
            />
          </section>
          <p className="b1-font text-color-white-87 margin-top-64 margin-top-48-md-only margin-top-32-sm-only">
            {article && article.text}
          </p>
          <div className="article-image-container">
            <img
              className="article-image | margin-top-32"
              src={process.env.REACT_APP_BACKEND_STATIC + article.image}
              alt={`${article.title}`}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Article;
