export const isImagesInCache = (images) => {
  let isImagesInCache = true;
  for (const image of images) {
    if (!window[image.imagePath || image]) {
      isImagesInCache = false;
      break;
    }
  }
  return isImagesInCache;
};

export const cacheImages = async (images) => {
  const promises = await images.map((image) => {
    return new Promise(function (resolve, reject) {
      const img = new Image();
      img.src = image.imagePath || image;
      img.onload = resolve();
      img.onerror = reject();
      window[img.src] = img;
    });
  });
  await Promise.all(promises);
};
