import "./nav-card.css";
import { Link } from "react-router-dom";

const NavCard = (props) => {
  const { title, backgroundImage, classes, to, state, target } = props;
  return (
    <Link
      className={`nav-card ${classes} | box-shadow-sm`}
      to={to}
      state={state}
      target={target}
    >
      <div
        style={{
          backgroundImage: `url(${
            backgroundImage ? backgroundImage : "/images/stock-caravan.png"
          })`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
        className="nav-card-bg-img"
      />
      <div className="nav-card-overlay" />
      <h1 className="nav-card-title | h6-font subtitle1-font-sm-only padding-inline-16 padding-block-12">
        {title}
      </h1>
    </Link>
  );
};

export default NavCard;
