import "./footer-section.css";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const FooterSection = () => {
  const { t: translate } = useTranslation();
  return (
    <section className="footer-section | background-color-primary-1000">
      <div className="container padding-top-48 padding-bottom-32">
        <div className="footer-section-content-wrapper | b2-font text-color-white-60">
          <div className="footer-section-logo-and-address-container">
            <div className="footer-logo-container">
              <img
                className="footer-logo"
                src="/logos/mops_yesil-beyaz-logo.svg"
              />
            </div>
            <div className="footer-section-address | margin-top-28">
              <p>Sepetlipınar Mah, Arpalık Cd. Küçük Sanayi Sitesi</p>
              <p>No:22, Blok No:6</p>
              <p>41275 Başiskele/Kocaeli</p>
            </div>
          </div>
          <div className="footer-section-nav-container">
            <div className="footer-section-nav-first-column">
              <Link to="/about-us" className="footer-section-nav-item">
                <p>{translate("about-us")}</p>
              </Link>
              <Link to="/model" className="footer-section-nav-item">
                <p>{translate("models")}</p>
              </Link>
              {/* <Link
                to="/model#design-your-caravan"
                className="footer-section-nav-item"
              >
                <p>{translate("design-your-caravan")}</p>
              </Link> */}
              <Link to="/gallery" className="footer-section-nav-item">
                <p>{translate("gallery")}</p>
              </Link>
            </div>
            <div className="footer-section-nav-second-column">
              <Link to="/pricing" className="footer-section-nav-item">
                <p>{translate("pricing")}</p>
              </Link>
              {/* <Link to="/blog" className="footer-section-nav-item">
                <p>{translate("blog")}</p>
              </Link> */}
              <Link to="/contact" className="footer-section-nav-item">
                <p>{translate("contact")}</p>
              </Link>
            </div>
          </div>
          <div className="footer-section-social-container">
            <Link
              to="https://m.facebook.com/p/Mops-Caravan-100063922544250/"
              target="_blank"
              className="footer-section-social-item"
            >
              <img
                className="footer-section-social-icon"
                src="/icons/facebook.svg"
                alt="Facebook"
              />
              <p> Facebook </p>
            </Link>
            <Link
              to="https://www.instagram.com/mopscaravan/"
              target="_blank"
              className="footer-section-social-item"
            >
              <img
                className="footer-section-social-icon"
                src="/icons/instagram.png"
                alt="Instagram"
              />
              <p> Instagram </p>
            </Link>
            <Link
              to="/contact#contact-us-form"
              target="_blank"
              className="footer-section-social-item"
            >
              <img
                className="footer-section-social-icon"
                src="/icons/mail-60.png"
                alt="E-mail"
              />
              <p> {translate("contact-us")} </p>
            </Link>
            <div className="footer-section-social-item">
              <img
                className="footer-section-social-phone-number-icon"
                src="/icons/call-60.svg"
                alt="phone number"
              />
              <p> +90 535 287 52 41 </p>
            </div>
            <div className="footer-section-social-item">
              <img
                className="footer-section-social-phone-number-icon"
                src="/icons/call-60.svg"
                alt="phone number"
              />
              <p> +90 262 502 11 41 </p>
            </div>
          </div>

          <p className="footer-section-copyright | text-color-white-38">
            Copyright © Mops Caravan | 2024
          </p>
        </div>
      </div>
    </section>
  );
};

export default FooterSection;
