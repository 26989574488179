import { useState, useEffect, useRef } from "react";
import "./gallery-section.css";
import { carouselSettings } from "./carousel-settings";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

const GallerySection = (props) => {
  const { images, activeImageIndex, changeActiveImageIndex, isFullScreen } =
    props;
  let sliderRef = useRef(null);
  const [nextImages, setNextImages] = useState(null);

  const onSlideChange = (index) => {
    changeActiveImageIndex(index);
  };

  useEffect(() => {
    if (images && images.length > 0) {
      if (images[activeImageIndex + 5]) {
        setNextImages([
          {
            imagePath: images[activeImageIndex + 1].imagePath,
            indexInImages: activeImageIndex + 1,
            isActive: false,
          },
          {
            imagePath: images[activeImageIndex + 2].imagePath,
            indexInImages: activeImageIndex + 2,
            isActive: false,
          },
          {
            imagePath: images[activeImageIndex + 3].imagePath,
            indexInImages: activeImageIndex + 3,
            isActive: false,
          },
          {
            imagePath: images[activeImageIndex + 4].imagePath,
            indexInImages: activeImageIndex + 4,
            isActive: false,
          },
          {
            imagePath: images[activeImageIndex + 5].imagePath,
            indexInImages: activeImageIndex + 5,
            isActive: false,
          },
        ]);
      } else {
        setNextImages([
          {
            imagePath: images[images.length - 5].imagePath,
            indexInImages: images.length - 5,
            isActive: activeImageIndex === images.length - 5,
          },
          {
            imagePath: images[images.length - 4].imagePath,
            indexInImages: images.length - 4,
            isActive: activeImageIndex === images.length - 4,
          },
          {
            imagePath: images[images.length - 3].imagePath,
            indexInImages: images.length - 3,
            isActive: activeImageIndex === images.length - 3,
          },
          {
            imagePath: images[images.length - 2].imagePath,
            indexInImages: images.length - 2,
            isActive: activeImageIndex === images.length - 2,
          },
          {
            imagePath: images[images.length - 1].imagePath,
            indexInImages: images.length - 1,
            isActive: activeImageIndex === images.length - 1,
          },
        ]);
      }
      sliderRef && sliderRef.slickGoTo(activeImageIndex);
    }
  }, [activeImageIndex, images]);

  return (
    <section className="gallery-section">
      <Slider
        {...carouselSettings}
        ref={(slider) => {
          sliderRef = slider;
        }}
        className="gallery-section-carousel"
        afterChange={(activeImageIndex) => onSlideChange(activeImageIndex)}
      >
        {images &&
          images.length > 0 &&
          images.map((image, index) => {
            return (
              <img
                key={`gallery-section-image-${index}`}
                id={`gallery-section-image-${index}`}
                className="gallery-section-image gallery-section-carousel-image"
                src={
                  process.env.REACT_APP_BACKEND_STATIC + image.imagePath ||
                  "/icons/mops-favicon.svg"
                }
                alt="caravan-image"
                data-fullscreen={isFullScreen}
              />
            );
          })}
      </Slider>
      <div className="gallery-section-next-images | margin-top-24 margin-top-16-md-only margin-top-12-sm-only">
        {nextImages &&
          nextImages.map((nextImage, index) => {
            return (
              <img
                key={`gallery-section-next-image-${index}`}
                id={`gallery-section-image-next-${index + 1}`}
                className="gallery-section-image gallery-section-next-image | box-shadow-sm"
                src={
                  process.env.REACT_APP_BACKEND_STATIC + nextImage.imagePath ||
                  "/icons/mops-favicon.svg"
                }
                alt="caravan-rv-gallery"
                onClick={() => changeActiveImageIndex(nextImage.indexInImages)}
              />
            );
          })}
      </div>
    </section>
  );
};

export default GallerySection;
