import "./models.css";
import { useTranslation } from "react-i18next";
import PageTitleSection from "../../components/page-title-section";
import ClickableModelsCard from "../../components/clickable-models-card";
import { modelKeys, modelValues } from "../../definitions/models";
import { useState, useEffect, useCallback } from "react";
import { cacheImages, isImagesInCache } from "../../utils/cache-images";

const Models = () => {
  const { t: translate } = useTranslation();
  const [pageTitleImage, setPageTitleImage] = useState(undefined);

  const [bulutCardImage, setBulutCardImage] = useState(undefined);
  const [dogaCardImage, setDogaCardImage] = useState(undefined);

  const callPageTitleImage = useCallback(async () => {
    const pageTitleImage = `/images/high-quality/header.svg`;
    if (!isImagesInCache([pageTitleImage])) await cacheImages([pageTitleImage]);
    setPageTitleImage(pageTitleImage);
  }, []);

  const callCardImages = useCallback(async () => {
    const bulutCardImage = `${process.env.REACT_APP_BACKEND_STATIC}/images/models/bulut-410/preview/high-quality/bulut-preview.jpg`;
    const dogaCardImage = `${process.env.REACT_APP_BACKEND_STATIC}/images/models/doga-350/preview/high-quality/doga-preview.jpg`;

    if (!isImagesInCache([bulutCardImage, dogaCardImage]))
      await cacheImages([bulutCardImage, dogaCardImage]);
    setBulutCardImage(bulutCardImage);
    setDogaCardImage(dogaCardImage);
  }, []);

  const setAllImages = async () => {
    await callPageTitleImage();
    await callCardImages();
  };

  useEffect(() => {
    setAllImages();
  }, []);

  const containerClasses =
    "container padding-block-64 padding-block-48-md-only padding-block-32-sm-only";
  return (
    <section id="models" className="models | background-color-primary-1000">
      <PageTitleSection
        id="models-page-title-section"
        className="padding-top-after-appbar"
        title={translate("models")}
        pageTitleImage={pageTitleImage}
      />
      <div className={containerClasses}>
        <div id="models-content" className="models-content">
          <div className={`models-cards`}>
            <ClickableModelsCard
              classes="background-color-primary-850"
              image={bulutCardImage}
              primaryTitle={modelValues["bulut-410"]}
              secondaryTitle={translate("trailer")}
              seats={3}
              beds={2}
              // price="$99999"
              to={`/model?model=${modelKeys["bulut-410"]}`}
            />
            <ClickableModelsCard
              classes="background-color-primary-850"
              image={
                process.env.REACT_APP_BACKEND_STATIC +
                "/images/models/doga-350/preview/high-quality/doga-preview.jpg"
              }
              primaryTitle={modelValues["doga-350"]}
              secondaryTitle={translate("trailer")}
              seats={3}
              beds={2}
              // price="$99999"
              to={`/model?model=${modelKeys["doga-350"]}`}
            />
            {/* <ModelsCard
              classes="background-color-primary-850"
              image={
                process.env.REACT_APP_BACKEND_STATIC +
                "/images/models/model-01/exterior-design/model-01-exterior-design-01.jpg"
              }
              primaryTitle="Model Adı"
              secondaryTitle={translate("trailer")}
              seats={3}
              beds={2}
              price="$99999"
              buttons={[
                <AdvancedButton
                  classes="background-color-primary-650 box-shadow-sm"
                  icon="/icons/arrow-right.svg"
                  primaryText={translate("details")}
                  secondaryText={translate("trailer") + " Model 1"}
                  to="/model"
                />,
                <AdvancedButton
                  classes="background-color-primary-650 box-shadow-sm"
                  icon="/icons/360.svg"
                  primaryText={translate("view-360")}
                  secondaryText={translate("trailer") + " Model 1"}
                  to=""
                />,
              ]}
            /> */}
            {/* <ModelsCard
              classes="background-color-primary-850"
              image={
                process.env.REACT_APP_BACKEND_STATIC +
                "/images/models/model-01/exterior-design/model-01-exterior-design-01.jpg"
              }
              primaryTitle="Model Adı"
              secondaryTitle={translate("trailer")}
              seats={3}
              beds={2}
              price="$99999"
              buttons={[
                <AdvancedButton
                  classes="background-color-primary-650"
                  icon="/icons/arrow-right.svg"
                  primaryText={translate("details")}
                  secondaryText={translate("trailer") + " Model 1"}
                />,
                <AdvancedButton
                  classes="background-color-primary-650"
                  icon="/icons/360.svg"
                  primaryText={translate("view-360")}
                  secondaryText={translate("trailer") + " Model 1"}
                />,
              ]}
            /> */}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Models;
