import "./home.css";
import Hero from "../../components/hero";
import GallerySection from "../../components/gallery-section";
import TestimonialSection from "../../components/testimonial-section";
import TitleDescriptionContent from "../../components/title-description-content";
import NavCard from "../../components/nav-card";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useTranslation } from "react-i18next";
import ImageMetaDataService from "../../services/images";
import BlogDataService from "../../services/blog";
import { useState, useEffect, useCallback } from "react";
import { modelKeys } from "../../definitions/models";
import { imageTypeKeys } from "../../definitions/image-types";
import { imageQualities } from "../../definitions/image-qualities";
import { cacheImages, isImagesInCache } from "../../utils/cache-images";
import { modelDetails } from "../../definitions/model-details";
const screenSizes = {
  lg: "lg",
  md: "md",
  sm: "sm",
  under400: "under-400",
};
const Home = () => {
  const { t: translate } = useTranslation();

  const containerClasses =
    "container padding-block-64 padding-block-48-md-only padding-block-32-sm-only";
  const carouselSectionContainerClasses =
    "container padding-block-48 padding-block-32-md-only padding-block-24-sm-only";

  const [heroBackgroundImage, setHeroBackgroundImage] = useState(undefined);
  const [testimonialBackgroundImage, setTestimonialBackgroundImage] =
    useState(undefined);
  const [galleryImageMetaList, setGalleryImageMetaList] = useState([]);
  const [partnerLogos, setPartnerLogos] = useState(undefined);

  const [activeGalleryImageIndex, setActiveGalleryImageIndex] = useState(0);
  const [activeHighlightCardIndex, setActiveHighlightCardIndex] = useState(0);

  const [articleList, setArticleList] = useState(null);
  const [screenSize, setScreenSize] = useState(null);

  const highlightsCarouselSettings = {
    dots: true,
    arrows: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    initialSlide: 0,
    dotsClass: "home-page-highlights-carousel-dots",
    beforeChange: (oldIndex, newIndex) => setActiveHighlightCardIndex(newIndex),
    appendDots: (dots) => {
      return (
        <ul>
          {dots.map((item, index) => {
            return (
              <li
                key={index}
                className={
                  activeHighlightCardIndex === index ||
                  (activeHighlightCardIndex > dots.length - 2 &&
                    index === dots.length - 1)
                    ? "slick-active"
                    : ""
                }
              >
                {item.props.children}
              </li>
            );
          })}
        </ul>
      );
    },
    responsive: [
      {
        breakpoint: 1600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const callHeroBackgroundImage = useCallback(async () => {
    let lowQualityHeroImage, highQualityHeroImage;
    if (window.matchMedia("(max-width: 600px)").matches) {
      lowQualityHeroImage = modelDetails["bulut-410"].heroImages.sm.lowQuality;
      highQualityHeroImage =
        modelDetails["bulut-410"].heroImages.sm.highQuality;
    } else if (window.matchMedia("(max-width: 1140px)").matches) {
      lowQualityHeroImage = modelDetails["bulut-410"].heroImages.md.lowQuality;
      highQualityHeroImage =
        modelDetails["bulut-410"].heroImages.md.highQuality;
    } else {
      lowQualityHeroImage = modelDetails["bulut-410"].heroImages.lg.lowQuality;
      highQualityHeroImage =
        modelDetails["bulut-410"].heroImages.lg.highQuality;
    }
    if (
      !isImagesInCache([lowQualityHeroImage]) &&
      !isImagesInCache([highQualityHeroImage])
    )
      setHeroBackgroundImage(lowQualityHeroImage);
    if (!isImagesInCache([highQualityHeroImage])) {
      await cacheImages([highQualityHeroImage]);
    }
    setHeroBackgroundImage(highQualityHeroImage);
  }, []);

  const callGalleryImages = useCallback(async () => {
    const lowQualityGalleryImagesResponse =
      await ImageMetaDataService.getImageMeta(
        [modelKeys["bulut-410"], modelKeys["doga-350"], "common"],
        [imageTypeKeys["interior-design"], imageTypeKeys["exterior-design"]],
        [imageQualities["low-quality"]]
      );
    const lowQualityGalleryImages =
      lowQualityGalleryImagesResponse.data.imageMetaList;
    const highQualityGalleryImagesResponse =
      await ImageMetaDataService.getImageMeta(
        [modelKeys["bulut-410"], modelKeys["doga-350"], "common"],
        [imageTypeKeys["interior-design"], imageTypeKeys["exterior-design"]],
        [imageQualities["high-quality"]]
      );

    const highQualityGalleryImages =
      highQualityGalleryImagesResponse.data.imageMetaList;
    if (
      !isImagesInCache([lowQualityGalleryImages]) &&
      !isImagesInCache([highQualityGalleryImages])
    )
      setGalleryImageMetaList(lowQualityGalleryImages);
    if (!isImagesInCache([highQualityGalleryImages]))
      await cacheImages(highQualityGalleryImages);
    setGalleryImageMetaList(highQualityGalleryImages);
  }, []);

  const callTestimonialBackground = useCallback(async () => {
    const lowQualityTestimonialBackground =
      "/images/low-quality/testimonial-background.jpg";

    const highQualityTestimonialBackground =
      "/images/high-quality/testimonial-background.jpg";

    if (
      !isImagesInCache([lowQualityTestimonialBackground]) &&
      !isImagesInCache([highQualityTestimonialBackground])
    )
      setTestimonialBackgroundImage(lowQualityTestimonialBackground);

    if (!isImagesInCache([highQualityTestimonialBackground]))
      await cacheImages([highQualityTestimonialBackground]);
    setTestimonialBackgroundImage(highQualityTestimonialBackground);
  }, []);

  const callPartnerLogos = useCallback(async () => {
    const partnerLogos = [
      "/logos/truma.svg",
      "/logos/can.svg",
      "/logos/mpk.svg",
      "/logos/hartal.svg",
      "/logos/thetford.svg",
      "/logos/berhimi.svg",
      "/logos/knott.svg",
      "/logos/alko.svg",
      "/logos/fiamma.svg",
      "/logos/aspock.svg",
      "/logos/elektrozirve.svg",
    ];
    if (!isImagesInCache([partnerLogos])) await cacheImages([partnerLogos]);
    setPartnerLogos(partnerLogos);
  }, []);

  const setAllImages = async () => {
    await callHeroBackgroundImage();
    await callGalleryImages();
    await callTestimonialBackground();
    await callPartnerLogos();
    window.addEventListener("resize", callHeroBackgroundImage);
  };
  useEffect(() => {
    setAllImages();
  }, []);

  useEffect(() => {
    // BlogDataService.getArticle("all").then((response) => {
    //   setArticleList(response.data.articleList);
    // });
  }, []);

  const checkScreenSize = useCallback(() => {
    if (window.matchMedia("(max-width: 400px)").matches)
      setScreenSize(screenSizes.under400);
    else if (window.matchMedia("(max-width: 600px)").matches)
      setScreenSize(screenSizes.sm);
    else if (window.matchMedia("(max-width: 900px)").matches)
      setScreenSize(screenSizes.md);
    else setScreenSize(screenSizes.lg);
  }, []);

  useEffect(() => {
    checkScreenSize();
    window.addEventListener("resize", checkScreenSize);
  }, [checkScreenSize]);

  return (
    <main>
      <Hero
        containerClasses="container padding-top-80 padding-top-64-md-only padding-top-48-sm-only padding-bottom-24"
        heroBackgroundImage={heroBackgroundImage}
      />
      <div className={`background-color-primary-900`}>
        <div className={`${containerClasses} hero-navcards-wrapper`}>
          <NavCard
            title={translate("models")}
            backgroundImage="/images/high-quality/trailer-illustration.png"
            classes="hero-navcard"
            to="/models"
          />
          <NavCard
            title={translate("pricing")}
            backgroundImage="/images/high-quality/pricing-illustration.png"
            classes="hero-navcard"
            to="/pricing"
          />
          <NavCard
            title={translate("about-us")}
            backgroundImage="/images/high-quality/about-us-illustration.png"
            classes="hero-navcard"
            to="/about-us"
          />
        </div>
      </div>

      {/* <VideoPreview containerClasses={containerClasses} /> */}
      {/* <TitleDescriptionContent
        classes="background-color-primary-800"
        containerClasses={carouselSectionContainerClasses}
        title={translate("highlights")}
      >
        <Slider
          {...highlightsCarouselSettings}
          className="home-highlights-carousel"
        >
          <ImageWithText
            image={"/images/stock-caravan.png"}
            secondaryText={
              "Lorem ipsum dolor sit amet Lorem ipsum dolor sit amet Lorem ipsum dolor sit amet"
            }
          />
          <ImageWithText
            image={"/images/stock-caravan.png"}
            secondaryText={"Lorem ipsum dolor sit amet"}
          />
          <ImageWithText
            image={"/images/stock-caravan.png"}
            secondaryText={"Lorem ipsum dolor sit amet"}
          />
          <ImageWithText
            image={"/images/stock-caravan.png"}
            secondaryText={"Lorem ipsum dolor sit amet"}
          />
          <ImageWithText
            image={"/images/stock-caravan.png"}
            secondaryText={"Lorem ipsum dolor sit amet"}
          />
        </Slider>
      </TitleDescriptionContent> */}
      {/* <TitleDescriptionContent
        classes="background-color-primary-900"
        containerClasses={containerClasses}
        title={translate("design-your-caravan")}
        description="İhtiyaçlarına uygun çekme karavanı oluştur, iç tasarımını yap ve opsiyonları seç. Tasarımını bizimle paylaş ve biz seninle iletişime geçelim."
      >
        <DesignPlayground
          classes="margin-top-40"
          layouts={[
            {
              primaryText: "Model XYZ",
              secondaryText: "2 Kişilik",
            },
            {
              primaryText: "Model YZQ",
              secondaryText: "4 Kişilik",
            },
          ]}
          colors={[
            {
              color: "#918988",
              colorName: "Pommery",
            },
            {
              color: "#937D66",
              colorName: "Morgan",
            },
            {
              color: "#4D4843",
              colorName: "Edgar",
            },
            {
              color: "#B4A290",
              colorName: "Matthiase",
            },
            {
              color: "#785456",
              colorName: "Misty Rose",
            },
            {
              color: "#A67C63",
              colorName: "Almond",
            },
          ]}
          otherColors={[
            {
              color: "#4D4843",
              colorName: "Edgar",
            },
            {
              color: "#B4A290",
              colorName: "Matthiase",
            },
            {
              color: "#785456",
              colorName: "Misty Rose",
            },
            {
              color: "#A67C63",
              colorName: "Almond",
            },
          ]}
          image="/images/stock-caravan.png"
        />
      </TitleDescriptionContent> */}
      <TitleDescriptionContent
        classes="background-color-primary-1000"
        containerClasses={containerClasses}
        title={translate("gallery")}
      >
        <GallerySection
          images={galleryImageMetaList}
          activeImageIndex={activeGalleryImageIndex}
          changeActiveImageIndex={(index) => {
            setActiveGalleryImageIndex(index);
          }}
          isFullScreen={false}
        />
      </TitleDescriptionContent>
      {/* <TitleDescriptionContent
        classes="background-color-primary-800"
        containerClasses={containerClasses}
        title={translate("why-mops-caravan")}
        // description="Lorem ipsum falan lorem ipsum falan lorem ipsum falan lorem ipsum
        // falan lorem ipsum falan lorem ipsum falan lorem ipsum falan"
      >
        <div className="home-why-mops-caravan-section-small-images-wrapper">
          <ImageWithText
            classes="home-why-mops-caravan-section-image-with-text"
            image="/images/stock-caravan.png"
            primaryText="Bir Başlık"
            secondaryText="Lorem ipsum dolor sit amet asdkljas kdjasdklj asdj asdjasdj jasdkljasdklj askasd kljaskdljjjkasdjkasjd "
          />
          <ImageWithText
            classes="home-why-mops-caravan-section-image-with-text"
            image="/images/stock-caravan.png"
            primaryText="Bir Başlık"
            secondaryText="Lorem ipsum dolor sit amet"
          />
          <ImageWithText
            classes="home-why-mops-caravan-section-image-with-text"
            image="/images/stock-caravan.png"
            primaryText="Bir Başlık"
            secondaryText="Lorem ipsum dolor sit amet"
          />
        </div>
      </TitleDescriptionContent> */}
      {/* {articleList && (
        <TitleDescriptionContent
          classes="background-color-gray-800"
          containerClasses={containerClasses}
          title={translate("recent-blog-posts")}
          description={[
            "Lorem ipsum falan lorem ipsum falan lorem ipsum falan lorem ipsum falan lorem ipsum falan lorem ipsum falan lorem ipsum falan",
          ]}
        >
          <div className="home-blog-navcards-wrapper">
            <NavCard
              title={translate(articleList[0].title)}
              backgroundImage={
                process.env.REACT_APP_BACKEND_STATIC + articleList[0].image
              }
              classes="home-blog-navcard"
              to={`/blog/${articleList[0].articleId}`}
              state={articleList[0]}
            />
            <NavCard
              title={translate(articleList[1].title)}
              backgroundImage={
                process.env.REACT_APP_BACKEND_STATIC + articleList[1].image
              }
              classes="home-blog-navcard"
              to={`/blog/${articleList[1].articleId}`}
              state={articleList[1]}
            />
            <NavCard
              title={translate("view-all")}
              backgroundImage={
                process.env.REACT_APP_BACKEND_STATIC + articleList[2].image
              }
              classes="home-blog-navcard"
              to={`/blog`}
            />
          </div>
        </TitleDescriptionContent>
      )} */}
      <TitleDescriptionContent
        classes="background-color-primary-900"
        containerClasses={containerClasses}
        title={translate("contact-us")}
        // description={[
        //   "Lorem ipsum falan lorem ipsum falan lorem ipsum falan lorem ipsum falan lorem ipsum falan lorem ipsum falan lorem ipsum falan",
        // ]}
      >
        <div className="home-contact-us-section-navcards-wrapper">
          <NavCard
            title={translate("whatsapp")}
            backgroundImage="/images/high-quality/whatsapp-illustration.png"
            classes="home-contact-us-section-navcard"
            to="//api.whatsapp.com/send?phone=905059277948&text=Merhaba, karavanlarınız hakkında bilgi almak istiyorum."
            target="_blank"
          />
          <NavCard
            title={translate("email")}
            backgroundImage="/images/high-quality/email-illustration.png"
            classes="home-contact-us-section-navcard"
            to="/contact#contact-us-form"
          />
        </div>
      </TitleDescriptionContent>
      <TestimonialSection
        containerClasses="container padding-block-120 padding-block-96-md-only padding-block-72-sm-only"
        isScreenUnder400={
          screenSize && screenSize.localeCompare("under-400") === 0
        }
        testimonialBackgroundImage={testimonialBackgroundImage}
        partnerLogos={partnerLogos}
      />
    </main>
  );
};

export default Home;
