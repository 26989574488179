import "./price-list-card.css";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const PriceListCard = (props) => {
  const { t: translate } = useTranslation();
  const {
    classes,
    image,
    primaryTitles,
    secondaryTitle,
    buttons,
    price,
    beds,
    people,
    modelKey,
  } = props;

  return (
    <div className={`price-list-card ${classes} box-shadow-sm`}>
      <div className="price-list-card-content-wrapper">
        <div className="price-list-card-image-container">
          <img className="price-list-card-image" src={image} />
        </div>
        <div className="price-list-card-texts-first-row-container | padding-inline-16 padding-top-16">
          <div className="price-list-card-model-column">
            <div className="price-list-card-model-column-titles">
              <div className="price-list-card-model-column-primary-titles">
                {primaryTitles.map((primaryTitle) => (
                  <p className="price-list-card-model-column-primary-title | h5-font h6-font-md-only h5-font-sm-only h6-font-under-400-only">
                    {primaryTitle}
                  </p>
                ))}
              </div>
              <p className="price-list-card-model-column-secondary-title | b2-font text-color-white-60 margin-top-4">
                {secondaryTitle}
              </p>
            </div>
          </div>
          <div className="price-list-card-price-column">
            <p className="price-list-card-price-column-price | h5-font h6-font-md-only h5-font-sm-only h6-font-under-400-only">
              {price}
            </p>
            <p className="price-list-card-price-column-options | button-font-md-only b2-font-sm-only button-font-under-400-only text-color-white-60 margin-top-4">
              + &nbsp;
              <Link
                to={`/model?model=${modelKey}#options`}
                target="_blank"
                className="text-color-primary-400"
              >
                {translate("options")}
              </Link>
            </p>
          </div>
        </div>
        <div className="price-list-card-model-column-beds-and-people | padding-inline-16 padding-top-16">
          <div className="price-list-card-model-column-beds-container | margin-top-24">
            <img
              className="price-list-card-model-column-seats-icon"
              src="/icons/bed.svg"
            />
            <span>{beds}</span>
          </div>
          <div className="price-list-card-model-column-people-container | margin-top-24">
            <img
              className="price-list-card-model-column-person-icon"
              src="/icons/person.svg"
            />
            <span>{people}</span>
          </div>
        </div>
        {buttons && buttons.length === 2 ? (
          <div className="price-list-card-buttons-container | padding-block-20 padding-inline-16">
            {buttons}
          </div>
        ) : (
          <div className="price-list-card-if-one-button-container | padding-block-20 padding-inline-16">
            {buttons}
          </div>
        )}
      </div>
    </div>
  );
};

export default PriceListCard;
