import "./clickable-models-card.css";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const ClickableModelsCard = (props) => {
  const { t: translate } = useTranslation();
  const {
    classes,
    image,
    primaryTitle,
    secondaryTitle,
    buttons,
    price,
    seats,
    beds,
    to,
  } = props;

  return (
    <Link
      className={`clickable-models-card ${classes} | box-shadow-sm text-color-white-87`}
      to={to}
    >
      <div className="clickable-models-card-content-wrapper">
        <div className="clickable-models-card-image-container">
          <img className="clickable-models-card-image" src={image} />
        </div>
        <div
          className={`clickable-models-card-texts-container | padding-inline-16 padding-top-16 padding-bottom-20`}
        >
          <div className="clickable-models-card-model-column">
            <div className="clickable-models-card-model-column-titles ">
              <p className="clickable-models-card-model-column-primary-title | h5-font h6-font-md-only h5-font-sm-only h6-font-under-400-only">
                {primaryTitle}
              </p>
              <p className="clickable-models-card-model-column-secondary-title | b2-font text-color-white-60 margin-top-4">
                {secondaryTitle}
              </p>
            </div>
          </div>
          <div className="clickable-models-card-price-column">
            <p className="clickable-models-card-price-column-price | h5-font h6-font-md-only h5-font-sm-only h6-font-under-400-only">
              {price}
            </p>
          </div>
        </div>
      </div>
      <div className="clickable-models-card-overlay" />
    </Link>
  );
};

export default ClickableModelsCard;
