import "./gallery.css";
import { useState, useEffect, useRef, useCallback } from "react";
import { useTranslation } from "react-i18next";
import PageTitleSection from "../../components/page-title-section";
import DropdownButton from "../../components/dropdown-button";
import ImageMetaDataService from "../../services/images";
import GallerySection from "../../components/gallery-section";
import { modelKeys, modelValues } from "../../definitions/models";
import { imageTypeKeys } from "../../definitions/image-types";
import { imageQualities } from "../../definitions/image-qualities";
import { cacheImages, isImagesInCache } from "../../utils/cache-images";
const Gallery = () => {
  const { t: translate } = useTranslation();
  const [imageMetaList, setImageMetaList] = useState([]);
  /**
   * Design Dropdown ***********
   */

  /** */
  const models = useRef([modelKeys["bulut-410"], modelKeys["doga-350"]]);
  const imageTypes = useRef([
    imageTypeKeys["interior-design"],
    imageTypeKeys["exterior-design"],
  ]);
  const [modelCheckStates, setModelCheckStates] = useState(
    models.current.map((item, index) => true)
  );
  const [imageTypeCheckStates, setImageTypeCheckStates] = useState(
    imageTypes.current.map((item, index) => true)
  );

  /** */

  const [activeImageIndex, setActiveImageIndex] = useState(undefined);

  const callGalleryImages = useCallback(
    async (checkedModels, checkedImageTypes) => {
      if (checkedModels.length > 0 && checkedImageTypes.length > 0) {
        const lowQualityGalleryImagesResponse =
          await ImageMetaDataService.getImageMeta(
            checkedModels,
            checkedImageTypes,
            [imageQualities["low-quality"]]
          );
        const lowQualityGalleryImages =
          lowQualityGalleryImagesResponse.data.imageMetaList;
        const highQualityGalleryImagesResponse =
          await ImageMetaDataService.getImageMeta(
            checkedModels,
            checkedImageTypes,
            [imageQualities["high-quality"]]
          );
        const highQualityGalleryImages =
          highQualityGalleryImagesResponse.data.imageMetaList;
        if (
          !isImagesInCache([lowQualityGalleryImages]) &&
          !isImagesInCache([highQualityGalleryImages])
        )
          setImageMetaList(lowQualityGalleryImages);
        if (!isImagesInCache([highQualityGalleryImages]))
          await cacheImages(highQualityGalleryImages);
        setImageMetaList(highQualityGalleryImages);
      } else setImageMetaList(null);
    },
    []
  );

  useEffect(() => {
    let checkedModels = modelCheckStates.map((modelCheckState, index) => {
      if (modelCheckState) return models.current[index];
      else return undefined;
    });
    checkedModels = checkedModels.filter((item) => item !== undefined);
    checkedModels && checkedModels.length > 0 && checkedModels.push("common");

    let checkedImageTypes = imageTypeCheckStates.map(
      (imageTypeCheckState, index) => {
        if (imageTypeCheckState) return imageTypes.current[index];
        else return undefined;
      }
    );
    checkedImageTypes = checkedImageTypes.filter((item) => item !== undefined);

    callGalleryImages(checkedModels, checkedImageTypes);
  }, [modelCheckStates, imageTypeCheckStates]);

  const onModelDropdownCheck = (checkboxStates) => {
    setModelCheckStates([...checkboxStates]);
  };

  const onDesignDropdownCheck = (checkboxStates) => {
    setImageTypeCheckStates([...checkboxStates]);
  };

  /** ***************************** */

  let containerClassesNarrow =
    "container-narrow container-narrow-md container-narrow-sm padding-block-64 padding-block-48-md-only padding-block-32-sm-only";
  const containerClasses =
    "container padding-block-64 padding-block-48-md-only padding-block-32-sm-only";
  return (
    <section id="gallery" className="gallery">
      <PageTitleSection
        id="gallery-page-title-section"
        className="padding-top-after-appbar"
        title={translate("gallery")}
        pageTitleImage={`/images/high-quality/header.svg`}
      />
      <div
        id="gallery-content"
        className="gallery-content | background-color-primary-1000"
      >
        <div className={`gallery-dropdowns | ${containerClassesNarrow}`}>
          <div className={`gallery-dropdown-container |  padding-bottom-0`}>
            <DropdownButton
              classes="background-color-primary-900"
              primaryText={translate("model")}
              notSelectedText={translate("no-model-selected")}
              items={models.current.map((model) => modelValues[model])}
              initialStates={modelCheckStates}
              onCheck={(checkboxStates) => onModelDropdownCheck(checkboxStates)}
            />
          </div>
          <div className={`gallery-dropdown-container | padding-bottom-0`}>
            <DropdownButton
              classes="background-color-primary-900"
              primaryText={translate("design")}
              notSelectedText={translate("no-design-details-selected")}
              items={imageTypes.current.map((imageType) =>
                translate(imageType)
              )}
              initialStates={imageTypeCheckStates}
              onCheck={(checkboxStates) =>
                onDesignDropdownCheck(checkboxStates)
              }
            />
          </div>
        </div>

        <div className={`gallery-images | ${containerClassesNarrow}`}>
          {imageMetaList &&
            imageMetaList.map((imageMeta, index) => {
              return (
                <img
                  className="gallery-image"
                  key={imageMeta.imagePath}
                  src={
                    process.env.REACT_APP_BACKEND_STATIC + imageMeta.imagePath
                  }
                  onClick={() => setActiveImageIndex(index)}
                />
              );
            })}
        </div>
        <div
          className={`gallery-carousel-container`}
          data-open={activeImageIndex !== undefined ? true : false}
        >
          <div className={"padding-block-32 padding-inline-80"}>
            {imageMetaList && (
              <GallerySection
                classes="gallery-carousel"
                images={imageMetaList}
                activeImageIndex={activeImageIndex}
                changeActiveImageIndex={(index) => {
                  setActiveImageIndex(index);
                }}
                isFullScreen={true}
              />
            )}
          </div>
          <img
            className="gallery-carousel-close-icon"
            onClick={() => setActiveImageIndex(undefined)}
            src="/icons/close.svg"
          />
        </div>
      </div>
    </section>
  );
};

export default Gallery;
