import http from "../http-common";

class EmailDataService {
  sendEmail(contactInputs) {
    alert("contactInputs: ", contactInputs);
    return http.post(`/email`, {
      ...contactInputs,
    });
  }
}

export default new EmailDataService();
