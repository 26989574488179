import "./partner-card.css";

const PartnerCard = (props) => {
  const { classes, image } = props;
  return (
    <div
      className={`partner-card | background-color-white-87 box-shadow-sm  ${classes}`}
    >
      <img src={image} className="partner-card-image" alt="our-partner" />
    </div>
  );
};

export default PartnerCard;
