import "./fab.css";

const FAB = (props) => {
  const { classes, icon, alt, onClick, isActive } = props;
  return (
    <div
      className={`fab box-shadow-sm ${classes}`}
      onClick={onClick}
      data-active={isActive}
    >
      <div className="fab-icon-container">
        <img className="fab-icon" src={icon} alt={alt} />
      </div>
      <div className="fab-overlay" />
    </div>
  );
};

export default FAB;
