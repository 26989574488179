import ImageLeftTextRight from "../image-left-text-right/image-left-text-right";
import TextLeftImagesRight from "../text-left-images-right/text-left-images-right";
import "./who-are-we-section.css";
import { useTranslation } from "react-i18next";

const WhoAreWeSection = (props) => {
  const { id, classes, containerClasses, image, text } = props;
  const { t: translate } = useTranslation();

  console.log("text: ", text);
  return (
    <section id={id} className={`about-us-content-who-are-we ${classes}`}>
      <div className={containerClasses}>
        <p className="about-us-content-who-are-we-title | h3-font h4-font-md-only">
          {translate("who-are-we")}
        </p>
        <img
          className="about-us-content-who-are-we-image | margin-top-28 box-shadow-sm"
          src={image}
          alt="who we are"
        />
        {text.map((row) => (
          <p className="about-us-content-who-are-we-paragraph | h5-font h6-font-md-only b1-font-sm-only margin-top-28">
            {row}
          </p>
        ))}

        {/* <ImageLeftTextRight
          classes="about-us-content-who-are-we-image-left-text-right | margin-top-48"
          primaryText="Bir Başlık"
          secondaryText="Nam lacinia ipsum quis felis egestas malesuada. Mauris scelerisque nulla
        at lacus sagittis finibus. Aenean tempor odio blandit est porta, sit
        amet tristique enim eleifend."
          image="/images/stock-caravan.png"
        />
        <TextLeftImagesRight
          classes="about-us-content-who-are-we-text-left-images-right | margin-top-48"
          primaryText="Bir Başlık"
          secondaryText="Nam lacinia ipsum quis felis egestas malesuada. Mauris scelerisque nulla
        at lacus sagittis finibus. Aenean tempor odio blandit est porta, sit
        amet tristique enim eleifend."
          images={["/images/stock-caravan.png"]}
        /> */}
      </div>
    </section>
  );
};

export default WhoAreWeSection;
