import { Outlet } from "react-router-dom";
import AppBar from "../../components/app-bar";
import "./layout.css";
import FooterSection from "../../components/footer-section";
import PrevNextPageSection from "../../components/prev-next-page-section";
import TopContactFABGroup from "../../components/top-contact-fab-group";

const Layout = () => {
  return (
    <div id="layout">
      <AppBar />
      <Outlet />
      <TopContactFABGroup />
      <PrevNextPageSection />
      <FooterSection />
    </div>
  );
};

export default Layout;
