import "./sub-features-section.css";

const SubFeaturesSection = (props) => {
  const { id, classes, title, children } = props;

  return (
    <section id={id} className={`sub-features-section ${classes}`}>
      <div className="sub-features-section-title-group">
        <p className="sub-features-section-title | h4-font">{title}</p>
        <div className="sub-features-section-fancy-divider-container">
          <img
            className="sub-features-section-fancy-divider"
            src="/images/high-quality/dotted-road.svg"
            alt="fancy divider"
          />
        </div>
      </div>
      <div className="sub-features-section-content | margin-top-48">
        {children}
      </div>
    </section>
  );
};

export default SubFeaturesSection;
