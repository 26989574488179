import "./top-contact-fab-group.css";
import FABGroup from "../../components/fab-group";
import FAB from "../../components/fab";
import { useEffect, useState, useCallback } from "react";
import { openWhatsapp } from "../../utils/open-whatsapp";

const TopContactFABGroup = (props) => {
  const { classes, id } = props;
  const [isVisible, setIsVisible] = useState(false);
  const [isContactOptionsVisible, setIsContactOptionsVisible] = useState(false);

  const checkScrollPosition = useCallback(() => {
    if (document.getElementById("root")) {
      let offsets = document.getElementById("root").getBoundingClientRect();
      if (offsets.top !== 0) {
        setIsVisible(true);
      } else setIsVisible(false);
    }
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", checkScrollPosition);
  }, [checkScrollPosition]);

  const upToTheTop = () => {
    window.scrollTo(0, 0);
  };

  const openContactForm = () => {
    window.open("/contact#contact-us-form", "_blank");
  };

  const toggleContactOptionsVisibility = () => {
    setIsContactOptionsVisible(
      (isContactOptionsVisible) => !isContactOptionsVisible
    );
  };

  return (
    <div
      className={`top-contact-fab-group ${classes}`}
      id={id}
      data-visible={isVisible}
    >
      <div className="top-contact-fab-group-main-fabs">
        <FAB
          classes="go-top-fab"
          icon="/icons/go-top.png"
          alt="Go to the top of the page"
          onClick={upToTheTop}
        />
        <FAB
          classes="contact-fab"
          isActive={isContactOptionsVisible}
          icon="/icons/chat.svg"
          alt="See contact options"
          onClick={toggleContactOptionsVisibility}
        />
      </div>

      <div
        className="top-contact-fab-group-option-fabs"
        data-visible={isContactOptionsVisible}
      >
        <FAB
          classes="close-fab"
          icon="/icons/close.svg"
          alt="Hide contact options"
          onClick={toggleContactOptionsVisibility}
        />
        <FAB
          classes="whatsapp-fab"
          icon="/icons/whatsapp.png"
          alt="Contact via Whatsapp"
          onClick={openWhatsapp}
        />
        <FAB
          classes="email-fab"
          icon="/icons/mail.png"
          alt="Contact via Email"
          onClick={openContactForm}
        />
      </div>
    </div>
  );
};

export default TopContactFABGroup;
