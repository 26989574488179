import { useState, useEffect } from "react";
// import { carouselSettings } from "./carousel-settings";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
// import { useTranslation } from "react-i18next";
import "./testimonial-section.css";
import ReviewCard from "../review-card";
import PartnerCard from "../partner-card";
import { useTranslation } from "react-i18next";

const TestimonialSection = (props) => {
  const { t: translate } = useTranslation();
  const {
    containerClasses,
    isScreenUnder400,
    testimonialBackgroundImage,
    partnerLogos,
  } = props;

  const [activeReviewCardIndex, setActiveReviewCardIndex] = useState(0);
  const [activePartnerCardIndex, setActivePartnerCardIndex] = useState(0);

  const testimonialCarouselSettings = {
    dots: true,
    arrows: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    initialSlide: 0,
    dotsClass: "testimonial-carousel-dots",
    beforeChange: (oldIndex, newIndex) => setActiveReviewCardIndex(newIndex),
    appendDots: (dots) => {
      return (
        <ul>
          {dots.map((item, index) => {
            return (
              <li
                key={index}
                className={
                  activeReviewCardIndex === index ||
                  (activeReviewCardIndex > dots.length - 2 &&
                    index === dots.length - 1)
                    ? "slick-active"
                    : ""
                }
              >
                {item.props.children}
              </li>
            );
          })}
        </ul>
      );
    },
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 400,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const partnersCarouselSettings = {
    dots: true,
    arrows: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    initialSlide: 0,
    dotsClass: "partners-carousel-dots",
    beforeChange: (oldIndex, newIndex) => setActivePartnerCardIndex(newIndex),
    appendDots: (dots) => {
      return (
        <ul>
          {dots.map((item, index) => {
            return (
              <li
                key={index}
                className={
                  activePartnerCardIndex === index ||
                  (activePartnerCardIndex > dots.length - 2 &&
                    index === dots.length - 1)
                    ? "slick-active"
                    : ""
                }
              >
                {item.props.children}
              </li>
            );
          })}
        </ul>
      );
    },
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 400,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        },
      },
    ],
  };

  useEffect(() => {
    const testimonialSection = document.querySelector("#testimonial-section");
    if (testimonialSection)
      testimonialSection.style.setProperty(
        "--testimonial-section-image-url",
        `url(${testimonialBackgroundImage})`
      );
  }, [testimonialBackgroundImage]);

  return (
    <section id="testimonial-section" className="testimonial-section">
      <div className={containerClasses}>
        {/* <Slider {...testimonialCarouselSettings} className="reviews-carousel">
          <ReviewCard
            reviewer="User"
            rating={2}
            text="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc velit nunc, ornare vel porta eget.
          Suspendisse varius neque ligula, quis blandit nisi sagittis at. Vestibulum ante risus, sagittis quis tincidunt ut."
          />
          <ReviewCard
            reviewer="User"
            rating={3}
            text="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc velit nunc, ornare vel porta eget.
          Suspendisse varius neque ligula, quis blandit nisi sagittis at. Vestibulum ante risus, sagittis quis tincidunt ut."
          />
          <ReviewCard
            reviewer="User"
            rating={4}
            text="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc velit nunc, ornare vel porta eget.
          Suspendisse varius neque ligula, quis blandit nisi sagittis at. Vestibulum ante risus, sagittis quis tincidunt ut."
          />
          <ReviewCard
            reviewer="User"
            rating={5}
            text="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc velit nunc, ornare vel porta eget.
          Suspendisse varius neque ligula, quis blandit nisi sagittis at. Vestibulum ante risus, sagittis quis tincidunt ut."
          />
          <ReviewCard
            reviewer="User"
            rating={5}
            text="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc velit nunc, ornare vel porta eget.
          Suspendisse varius neque ligula, quis blandit nisi sagittis at. Vestibulum ante risus, sagittis quis tincidunt ut."
          />
          <ReviewCard
            reviewer="User"
            rating={5}
            text="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc velit nunc, ornare vel porta eget.
          Suspendisse varius neque ligula, quis blandit nisi sagittis at. Vestibulum ante risus, sagittis quis tincidunt ut."
          />
        </Slider> */}
        <p className="title-description-content-title | h3-font h4-font-md-only text-color-primary-800">
          {translate("our-partners")}
        </p>
        <Slider
          {...partnersCarouselSettings}
          className="partners-carousel | margin-top-80 margin-top-64-md-only margin-top-48-sm-only"
        >
          {partnerLogos &&
            partnerLogos.map((partnerLogo) => (
              <div key={partnerLogo}>
                <PartnerCard image={partnerLogo} />
              </div>
            ))}
        </Slider>
      </div>
    </section>
  );
};

export default TestimonialSection;
