import "./prev-next-page-section.css";
import { useTranslation } from "react-i18next";
import { useLocation, Link } from "react-router-dom";
import { modelKeys } from "../../definitions/models";

const PrevNextPageSection = () => {
  const { t: translate } = useTranslation();
  const currentPage = useLocation();
  const pages = [
    "/",
    "/about-us",
    "/models",
    `/model?model=${modelKeys["bulut-410"]}`,
    `/model?model=${modelKeys["doga-350"]}`,
    "/gallery",
    "/pricing",
    // "/blog",
    "/contact",
  ];

  const fullURL = window.location.href; // http://localhost:3000/model?model=doga-350#options
  const fullURLSplit = fullURL.split("/");
  let pathAndQueryParamsWithHashtag =
    "/" + fullURLSplit[fullURLSplit.length - 1]; // /model?model=doga-350#options
  let pathAndQueryParamsWithoutHashtag =
    pathAndQueryParamsWithHashtag.split("#")[0]; // /model?model=doga-350

  const currentPageIndex = pages.indexOf(pathAndQueryParamsWithoutHashtag);
  const prevPage = pages[currentPageIndex - 1];
  const nextPage = pages[currentPageIndex + 1];

  return (
    <section className="prev-next-page-section | padding-top-24">
      {prevPage ? (
        <Link
          className="prev-page-button | text-color-white-87 text-decoration-none"
          to={prevPage}
        >
          <p className="prev-page-secondary-text | overline-font text-uppercase">
            {translate("prev-page")}
          </p>
          <p className="prev-page-primary-text | h6-font margin-top-4">
            {prevPage.slice(1)
              ? translate(prevPage.slice(1))
              : translate("home-page")}
          </p>
        </Link>
      ) : null}
      {nextPage ? (
        <Link
          className="next-page-button | text-color-white-87 text-decoration-none"
          to={nextPage}
        >
          <p className="next-page-secondary-text | overline-font text-uppercase">
            {translate("next-page")}
          </p>
          <p className="next-page-primary-text | h6-font margin-top-4">
            {translate(nextPage.slice(1))}
          </p>
        </Link>
      ) : null}
    </section>
  );
};

export default PrevNextPageSection;
