import "./contact.css";
import PageTitleSection from "../../components/page-title-section";
import TextField from "../../components/text-field";
import TextArea from "../../components/text-area";
import Dropdown from "../../components/dropdown";
import Button from "../../components/button";
import { useTranslation } from "react-i18next";
import { useState, useEffect } from "react";
import { countryCodes } from "./country-codes";
import EmailDataService from "../../services/email";
import { openWhatsapp } from "../../utils/open-whatsapp";

const Contact = () => {
  const { t: translate } = useTranslation();
  // const [contact, setContact] = useState(null);
  const [isSubscribePolicyChecked, setIsSubscribePolicyChecked] =
    useState(true);
  const [selectedCountryCodeIndex, setSelectedCountryCodeIndex] = useState(46);

  const [contactInputs, setContactInputs] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    messageTitle: "",
    message: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setContactInputs({
      ...contactInputs,
      [name]: value,
    });
  };

  // const onSubscribePolicyCheck = () => {
  //   setIsSubscribePolicyChecked(
  //     (isSubscribePolicyChecked) => !isSubscribePolicyChecked
  //   );
  // };

  /** to scroll into section automatically when visited from another page (like contact > contact-us-form) */
  useEffect(() => {
    const href = window.location.href.substring(
      window.location.href.lastIndexOf("#") + 1
    );
    const element = document.getElementById(href);
    if (element) {
      element.scrollIntoView();
    }

    /** sort by dial_code
     * */
    // console.log(
    //   JSON.stringify(
    //     countryCodes.sort(function (a, b) {
    //       let x = Number(a.dial_code.slice(1));
    //       let y = Number(b.dial_code.slice(1));
    //       if (x < y) {
    //         return -1;
    //       }
    //       if (x > y) {
    //         return 1;
    //       }
    //       return 0;
    //     })
    //   )
    // );
  }, []);

  const sendEmail = (contactInputs) => {
    EmailDataService.sendEmail(contactInputs).then((response) => {
      if (response.status === 200) {
        setContactInputs({
          firstName: "",
          lastName: "",
          email: "",
          phoneNumber: "",
          messageTitle: "",
          message: "",
        });
        alert("Email gönderildi.");
      } else
        alert(
          "Email gönderilirken hata oluştu, lütfen whatsapp üzerinden iletişime geçiniz."
        );
    });
  };
  let containerClassesNarrow =
    "container-narrow container-narrow-md container-narrow-sm padding-block-64 padding-block-48-md-only padding-block-32-sm-only";
  return (
    <section className="contact | background-color-primary-1000">
      <PageTitleSection
        id="contact-page-title-section"
        className="padding-top-after-appbar"
        title={translate("contact")}
      />
      <div className={containerClassesNarrow}>
        <div className="contact-content-wrapper">
          <img
            className="margin-top-48"
            src="/images/high-quality/dotted-road.svg"
            alt="fancy divider"
          />
          <div className="contact-address-section | margin-top-72">
            <div className="contact-address-texts">
              <p className="contact-address-title | h3-font h4-font-md-only">
                {translate("address")}
              </p>
              <div className="contact-address-address | h5-font h6-font-md-only b1-font-sm-only margin-top-20">
                <p>Sepetlipınar Mah, Arpalık Cd. Küçük Sanayi Sitesi</p>
                <p>No:22, Blok No:6</p>
                <p>41275 Başiskele/Kocaeli</p>
                <p className="contact-address-section-phone-numbers | margin-top-32">
                  <div className="contact-address-section-phone-number-container">
                    <img
                      className="contact-address-section-phone-number-icon"
                      src="/icons/call.svg"
                      alt="phone number"
                    />
                    <p> (0262) 502 11 41 </p>
                  </div>
                  <div className="contact-address-section-phone-number-container">
                    <img
                      className="contact-address-section-phone-number-icon"
                      src="/icons/call.svg"
                      alt="phone number"
                    />
                    <p> +90 535 287 52 41 </p>
                  </div>
                </p>
              </div>
            </div>
            <div className="contact-address-location-container | margin-top-40">
              <iframe
                className="contact-address-location"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3024.023018308384!2d29.856130676747018!3d40.71750977139276!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14cb46ae4e8a7b3d%3A0xc54523e9e11e3a70!2sMops%20Caravan!5e0!3m2!1str!2str!4v1702387914321!5m2!1str!2str"
                width="580"
                height="450"
                style={{ border: 0 }}
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
          </div>
          <div className="contact-contact-us-section | margin-top-80 margin-top-64-md-only margin-top-48-sm-only">
            <div className="contact-contact-us-texts">
              <p className="contact-contact-us-header | h3-font h4-font-md-only">
                {translate("contact-us")}
              </p>
              {/* <p className="contact-contact-us-text | h5-font h6-font-md-only b1-font-sm-only margin-top-20">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor
                in reprehenderit in.
              </p> */}
            </div>
            <div
              id="contact-us-form"
              className="contact-contact-us-form-container | margin-top-64 margin-top-48-md-only margin-top-32-sm-only"
            >
              <div className="contact-contact-us-your-info-form-container">
                <p className="contact-contact-us-your-info-form-title | h4-font h5-font-md-only">
                  {translate("your-information")}
                </p>
                <div className="contact-contact-us-your-info-form-row contact-contact-us-your-info-form-name-last-name-row | margin-top-24">
                  <div className="contact-contact-us-your-info-form-row-first-column">
                    <TextField
                      type="text"
                      name="firstName"
                      classes="contact-contact-us-first-name-text-field"
                      placeholderText={translate("name")}
                      text={contactInputs.firstName}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="contact-contact-us-your-info-form-row-second-column">
                    <TextField
                      type="text"
                      name="lastName"
                      classes="contact-contact-us-last-name-text-field"
                      placeholderText={translate("last-name")}
                      text={contactInputs.lastName}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
                <div className="contact-contact-us-your-info-form-row contact-contact-us-your-info-form-email-phone-row | margin-top-24">
                  <div className="contact-contact-us-your-info-form-row-first-column">
                    <TextField
                      type="email"
                      name="email"
                      classes="contact-contact-us-email-text-field"
                      placeholderText={translate("email")}
                      text={contactInputs.email}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="contact-contact-us-your-info-form-row-second-column">
                    <div className="contact-contact-us-your-info-phone-number-container">
                      <Dropdown
                        name="phoneNumberCode"
                        classes="contact-contact-us-your-info-phone-number-code-dropdown"
                        upperPartPaddingInlineClasses="padding-inline-16"
                        upperPartPaddingBlockClasses="padding-block-16"
                        lowerPartPaddingInlineClasses="padding-inline-16"
                        items={countryCodes.map(
                          (countryCode) => countryCode.dial_code
                        )}
                        selectedItemIndex={selectedCountryCodeIndex}
                        onSelect={setSelectedCountryCodeIndex}
                      />
                      <TextField
                        type="tel"
                        name="phoneNumber"
                        classes="signup-form-phone-number-text-field"
                        placeholderText="Phone number"
                        text={contactInputs.phoneNumber}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="contact-contact-us-your-message-form-container | margin-top-72 margin-top-64-md-only margin-top-48-sm-only">
                <p className="contact-contact-us-your-message-form-title | h4-font h5-font-md-only">
                  {translate("your-message")}
                </p>
                <div className="contact-contact-us-your-message-form-row contact-contact-us-your-message-form-message-header-row | margin-top-24">
                  <div className="contact-contact-us-your-message-form-row-first-column">
                    <TextField
                      type="text"
                      name="messageTitle"
                      classes="contact-contact-us-message-header-text-field"
                      placeholderText={translate("message-header")}
                      text={contactInputs.messageTitle}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
                <div className="margin-top-24">
                  <TextArea
                    name="message"
                    classes="contact-contact-us-message-text-area"
                    placeholderText={translate("type-your-message-here")}
                    text={contactInputs.message}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
              <div className="contact-contact-us-policy-texts | margin-top-32">
                {/* <div className="contact-contact-us-policy-text-container">
                  <div className="contact-contact-us-policy-checkbox-container">
                    <img
                      src={
                        isPrivacyPolicyChecked
                          ? "/icons/checkbox-checked.svg"
                          : "/icons/checkbox-blank.svg"
                      }
                      alt="Privacy Policy"
                      onClick={onPrivacyPolicyCheck}
                    />
                  </div>

                  <p>
                    I have read, understood and accept the terms of the{" "}
                    <a href="">Privacy Policy</a> and{" "}
                    <a href="">Terms of Use</a>.
                  </p>
                </div> */}
                {/* <div className="contact-contact-us-policy-text-container">
                  <div className="contact-contact-us-policy-checkbox-container">
                    <img
                      className="contact-contact-us-policy-checkbox"
                      src={
                        isSubscribePolicyChecked
                          ? "/icons/checkbox-checked.svg"
                          : "/icons/checkbox-blank.svg"
                      }
                      alt="Privacy Policy"
                      onClick={onSubscribePolicyCheck}
                    />
                  </div>
                  <p className="b2-font button-font-sm-only">
                    I agree to receive regular e-mails from your company and
                    subscribe to the newsletter.
                  </p>
                </div> */}
              </div>
              <div className="contact-contact-us-buttons | margin-top-48">
                <Button
                  classes="contact-contact-us-button"
                  leftIcon="/icons/whatsapp.png"
                  text={translate("whatsapp")}
                  onClick={openWhatsapp}
                />
                <Button
                  classes="contact-contact-us-button"
                  text={translate("send")}
                  isPrimary={true}
                  onClick={() => sendEmail(contactInputs)}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;
