import "./dropdown.css";
import { useState } from "react";

const Dropdown = (props) => {
  const {
    classes,
    items,
    selectedItemIndex,
    onSelect,
    isPrimary = false,
    hasBorder = true,
    upperPartPaddingInlineClasses,
    upperPartPaddingBlockClasses,
    lowerPartPaddingInlineClasses,
    lowerPartPaddingBlockClasses,
  } = props;

  const [isDropdownExpanded, setIsDropdownExpanded] = useState(false);
  const toggleDropdown = () => {
    setIsDropdownExpanded(!isDropdownExpanded);
  };

  const handleItemSelect = (itemIndex) => {
    toggleDropdown();
    onSelect(itemIndex);
  };

  return (
    <div
      className={`dropdown | b1-font text-decoration-none box-shadow-sm ${classes}`}
      role="dropdown"
      data-primary={isPrimary}
      data-expanded={isDropdownExpanded}
    >
      <div
        className={`dropdown-upper-part | ${
          upperPartPaddingInlineClasses
            ? upperPartPaddingInlineClasses
            : "padding-inline-32 padding-inline-16-under-400-only"
        } 
         ${
           upperPartPaddingBlockClasses
             ? upperPartPaddingBlockClasses
             : "padding-block-24"
         }`}
        onClick={toggleDropdown}
        data-primary={isPrimary}
        data-expanded={isDropdownExpanded}
        data-border={hasBorder}
      >
        <div className="dropdown-upper-text-container">
          <p className="dropdown-upper-text | b1-font">
            {items[selectedItemIndex]}
          </p>
        </div>
        <div className="dropdown-arrow-container">
          <img
            className="dropdown-arrow"
            src={
              isDropdownExpanded
                ? "/icons/expand-less.svg"
                : "/icons/expand-more.svg"
            }
          />
        </div>
      </div>
      <div className="dropdown-anchor">
        <div
          className="dropdown-lower-part | padding-bottom-16"
          data-primary={isPrimary}
          data-expanded={isDropdownExpanded}
          data-border={hasBorder}
        >
          {items.map((item, index) => (
            <div
              className={`dropdown-lower-part-item | ${
                lowerPartPaddingInlineClasses
                  ? lowerPartPaddingInlineClasses
                  : "padding-inline-32"
              }
              ${
                lowerPartPaddingBlockClasses
                  ? lowerPartPaddingBlockClasses
                  : "padding-block-16"
              }`}
              onClick={() => handleItemSelect(index)}
            >
              <p className="b1-font subtitle1-font-sm-only text-neutral-400">
                {item}
              </p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Dropdown;
