export const modelDetails = {
  "bulut-410": {
    name: "Bulut 410",
    heroImages: {
      lg: {
        lowQuality: `${process.env.REACT_APP_BACKEND_STATIC}/images/models/bulut-410/hero/low-quality/bulut-410-hero-desktop.jpg`,
        highQuality: `${process.env.REACT_APP_BACKEND_STATIC}/images/models/bulut-410/hero/high-quality/bulut-410-hero-desktop.jpg`,
      },
      md: {
        lowQuality: `${process.env.REACT_APP_BACKEND_STATIC}/images/models/bulut-410/hero/low-quality/bulut-410-hero-900.jpg`,
        highQuality: `${process.env.REACT_APP_BACKEND_STATIC}/images/models/bulut-410/hero/high-quality/bulut-410-hero-900.jpg`,
      },
      sm: {
        lowQuality: `${process.env.REACT_APP_BACKEND_STATIC}/images/models/bulut-410/hero/low-quality/bulut-410-hero-600.jpg`,
        highQuality: `${process.env.REACT_APP_BACKEND_STATIC}/images/models/bulut-410/hero/high-quality/bulut-410-hero-600.jpg`,
      },
    },
    modelDimensions: {
      // secondaryTexts: [
      //   "• Toplam uzunluk: 5100mm",
      //   "• Toplam genişlik: 2120mm",
      //   "• Toplam yükseklik: 2475mm",
      //   "• Kabin uzunluğu: 4070mm",
      //   "• İç yükseklik: 1970mm",
      //   "• İç genişlik: 2040mm",
      // ],
      images: {
        lowQuality: [
          `${process.env.REACT_APP_BACKEND_STATIC}/images/models/bulut-410/exterior-design/low-quality/bulut-410_dis-1.jpg`,
        ],
        highQuality: [
          `${process.env.REACT_APP_BACKEND_STATIC}/images/models/bulut-410/exterior-design/high-quality/bulut-410_dis-1.jpg`,
        ],
      },
    },
    layouts: {
      primaryTexts: ["Plan 1", "Plan 2"],
      // secondaryTexts: [
      //   ["Yatak Sayısı: 2", "Yatak Kapasitesi (Kişi): 3-4"],
      //   ["Yatak Sayısı: 3", "Yatak Kapasitesi (Kişi): 3-4"],
      // ],
      images: {
        highQuality: [
          `${process.env.REACT_APP_BACKEND_STATIC}/images/models/bulut-410/layout/bulut-model-1.svg`,
          `${process.env.REACT_APP_BACKEND_STATIC}/images/models/bulut-410/layout/bulut-model-2.svg`,
        ],
      },
    },
    equipmentDetails: {
      furniture: {
        // secondaryTexts: [
        //   "• VENNI & NOPILPAN Laminant Baskı Marin Kavak Kontraplak",
        //   "• Gizli Bağlantı Sistemli Vidalama",
        //   "• HAFELE Gizli Kilit Sistemli Kapaklar",
        //   "• BALDACCI Bas Çek Kilit",
        //   "• CAN Ocak Ve Katlanır Musluk",
        //   "• CAN Kombinasyon Tek Gözlü Ocak",
        //   "• LIPPERT 360 Derece Masa Ayağı",
        //   "• Kapı Sinekliği",
        // ],
        images: {
          lowQuality: [
            `${process.env.REACT_APP_BACKEND_STATIC}/images/models/bulut-410/interior-design/low-quality/bulut-410_yasam-alani-8.jpg`,
            `${process.env.REACT_APP_BACKEND_STATIC}/images/models/common/interior-design/low-quality/mutfak-1.jpg`,
            `${process.env.REACT_APP_BACKEND_STATIC}/images/models/bulut-410/interior-design/low-quality/bulut-410_mutfak-3.jpg`,
            `${process.env.REACT_APP_BACKEND_STATIC}/images/models/bulut-410/interior-design/low-quality/bulut-410_yatak-odasi-5.jpg`,
          ],
          highQuality: [
            `${process.env.REACT_APP_BACKEND_STATIC}/images/models/bulut-410/interior-design/high-quality/bulut-410_yasam-alani-8.jpg`,
            `${process.env.REACT_APP_BACKEND_STATIC}/images/models/common/interior-design/high-quality/mutfak-1.jpg`,
            `${process.env.REACT_APP_BACKEND_STATIC}/images/models/bulut-410/interior-design/high-quality/bulut-410_mutfak-3.jpg`,
            `${process.env.REACT_APP_BACKEND_STATIC}/images/models/bulut-410/interior-design/high-quality/bulut-410_yatak-odasi-5.jpg`,
          ],
        },
      },
      bathroom: {
        // secondaryTexts: [
        //   "• Portatif / Kasetli Tuvalet",
        //   "• Spiralli Tetik Başlıklı Musluk",
        //   "• Geniş Kullanımlı Banyo Dolapları",
        //   "• Duş Perdesi",
        //   "• 28x28 Fanlı Banyo Tavan Heki",
        // ],
        images: {
          lowQuality: [
            `${process.env.REACT_APP_BACKEND_STATIC}/images/models/bulut-410/interior-design/low-quality/bulut-410_banyo-3.jpg`,
            `${process.env.REACT_APP_BACKEND_STATIC}/images/models/bulut-410/interior-design/low-quality/bulut-410_banyo-1.jpg`,
          ],
          highQuality: [
            `${process.env.REACT_APP_BACKEND_STATIC}/images/models/bulut-410/interior-design/high-quality/bulut-410_banyo-3.jpg`,
            `${process.env.REACT_APP_BACKEND_STATIC}/images/models/bulut-410/interior-design/high-quality/bulut-410_banyo-1.jpg`,
          ],
        },
      },
      lighting: {
        secondaryTexts: [
          "• Dolap Üstü 12 V Spot Aydınlatma",
          "• 12 V Kabin içi Aydınlatma Spotları",
          "• USB Prizler",
        ],
        images: {
          lowQuality: [
            `${process.env.REACT_APP_BACKEND_STATIC}/images/models/bulut-410/interior-design/low-quality/bulut-410_mutfak-1.jpg`,
            `${process.env.REACT_APP_BACKEND_STATIC}/images/models/bulut-410/interior-design/low-quality/bulut-410_yatak-odasi-4.jpg`,
          ],
          highQuality: [
            `${process.env.REACT_APP_BACKEND_STATIC}/images/models/bulut-410/interior-design/high-quality/bulut-410_mutfak-1.jpg`,
            `${process.env.REACT_APP_BACKEND_STATIC}/images/models/bulut-410/interior-design/high-quality/bulut-410_yatak-odasi-4.jpg`,
          ],
        },
      },
      "control-panel": {
        // secondaryTexts: ["• IOT Kaan Karavan Otomasyonu", "• HONOR Tablet "],
        images: {
          lowQuality: [
            `${process.env.REACT_APP_BACKEND_STATIC}/images/models/common/interior-design/low-quality/kontrol-paneli-1.jpg`,
          ],
          highQuality: [
            `${process.env.REACT_APP_BACKEND_STATIC}/images/models/common/interior-design/high-quality/kontrol-paneli-1.jpg`,
          ],
        },
      },
      "gas-system": {
        // secondaryTexts: [
        //   "• Truma Gaz Vanaları",
        //   "• Bakır Gaz Tesisatı",
        //   "• Tüp Tutucu",
        // ],
        images: {
          lowQuality: [
            `${process.env.REACT_APP_BACKEND_STATIC}/images/models/common/exterior-design/low-quality/gas-system-1.jpg`,
          ],
          highQuality: [
            `${process.env.REACT_APP_BACKEND_STATIC}/images/models/common/exterior-design/high-quality/gas-system-1.jpg`,
          ],
        },
      },
      "window-system": {
        // secondaryTexts: ["• BERHIMI Window Vision Pencere Sistemi"],
        images: {
          lowQuality: [
            `${process.env.REACT_APP_BACKEND_STATIC}/images/models/bulut-410/interior-design/low-quality/bulut-410_yasam-alani-9.jpg`,
            `${process.env.REACT_APP_BACKEND_STATIC}/images/models/bulut-410/interior-design/low-quality/bulut-410_yatak-odasi-8.jpg`,
          ],
          highQuality: [
            `${process.env.REACT_APP_BACKEND_STATIC}/images/models/bulut-410/interior-design/high-quality/bulut-410_yasam-alani-9.jpg`,
            `${process.env.REACT_APP_BACKEND_STATIC}/images/models/bulut-410/interior-design/high-quality/bulut-410_yatak-odasi-8.jpg`,
          ],
        },
      },
      "solar-energy-system": {
        // secondaryTexts: [
        //   "• 2 Adet 205 Watt Halfcut Güneş Paneli ",
        //   "• NK1000-12 Apex Tam Sinüs invertör ",
        //   "• ELECTROZIRVE ML2430 30 A Solar Charge Kontrol",
        //   "• ELECTROZIRVE 12,8 V 150 Ah Lityum Akü (LIFEPO4)",
        //   "• 10 A Transfer Switch ",
        //   "• MEANWELL NPB360-12 20 A Akü Şarj Cihazı ",
        // ],
        images: {
          lowQuality: [
            `${process.env.REACT_APP_BACKEND_STATIC}/images/models/bulut-410/exterior-design/low-quality/bulut-410_dis-4.jpg`,
          ],
          highQuality: [
            `${process.env.REACT_APP_BACKEND_STATIC}/images/models/bulut-410/exterior-design/high-quality/bulut-410_dis-4.jpg`,
          ],
        },
      },
      "heating-system": {
        // secondaryTexts: [
        //   "• Trumatic S3004 Gazlı Soba (Titanyum Gri)",
        //   "• Truma Therme (220 V)",
        // ],
        images: {
          lowQuality: [
            `${process.env.REACT_APP_BACKEND_STATIC}/images/models/common/interior-design/low-quality/kontrol-paneli-1.jpg`,
          ],
          highQuality: [
            `${process.env.REACT_APP_BACKEND_STATIC}/images/models/common/interior-design/high-quality/kontrol-paneli-1.jpg`,
          ],
        },
      },
      "water-tank-and-plumming": {
        // secondaryTexts: [
        //   "• FIAMMA 70 L Temiz Su Deposu",
        //   "• Soğuk Sıcak Su Hortumu ",
        //   "• Plastik Geçme Bağlantı Aparatları ",
        //   "• PPRC Kirli Su Tesisatı (25 mm) ",
        // ],
        images: {
          lowQuality: [
            `${process.env.REACT_APP_BACKEND_STATIC}/images/models/bulut-410/interior-design/low-quality/bulut-410_mutfak-4.jpg`,
          ],
          highQuality: [
            `${process.env.REACT_APP_BACKEND_STATIC}/images/models/bulut-410/interior-design/high-quality/bulut-410_mutfak-4.jpg`,
          ],
        },
      },
      "exterior-equipments": {
        // secondaryTexts: [
        //   "• Tavan Havalandırma Heki",
        //   "• Elektrik Girişi (Beyaz)",
        //   "• Kilitli Su Dolum Ağzı (Beyaz)",
        //   "• Tetikli Dış Duş (Beyaz )",
        //   "• JOKON WW250 Tente Ortam Işığı",
        //   "• HARTAL Servis Kapıları",
        //   "• HARTAL Karavan Kapısı",
        //   "• Ön Arka Tampon Özel Tasarım Polyester Döküm ",
        //   "• JOKON Aydınlatma Grubu ",
        // ],
        images: {
          lowQuality: [
            `${process.env.REACT_APP_BACKEND_STATIC}/images/models/common/exterior-design/low-quality/dis-1.jpg`,
            `${process.env.REACT_APP_BACKEND_STATIC}/images/models/common/exterior-design/low-quality/dis-9.jpg`,
          ],
          highQuality: [
            `${process.env.REACT_APP_BACKEND_STATIC}/images/models/common/exterior-design/high-quality/dis-1.jpg`,
            `${process.env.REACT_APP_BACKEND_STATIC}/images/models/common/exterior-design/high-quality/dis-9.jpg`,
          ],
        },
      },
      body: {
        // secondaryTexts: [
        //   "• Vakum Press Yöntemiyle Uygun Basınç Ve Laminasyon Tekniği ile CTP Panel Üretimi (High Gloss, Gofrajlı)",
        //   "• Yan Duvarlar: 33 mm, Tavan: 33 mm, Taban: 41 mm",
        //   "• iç Yüzeyler Anti Bakteriyel Kumaş Kaplama",
        // ],
        images: {
          lowQuality: [
            `${process.env.REACT_APP_BACKEND_STATIC}/images/models/bulut-410/exterior-design/low-quality/bulut-410_dis-3.jpg`,
          ],
          highQuality: [
            `${process.env.REACT_APP_BACKEND_STATIC}/images/models/bulut-410/exterior-design/high-quality/bulut-410_dis-3.jpg`,
          ],
        },
      },
      chassis: {
        // secondaryTexts: [
        //   "• KNOTT 1350 kg Torsiyonlu Aks Frenli Dingil 1400mm",
        //   "• KNOTT Stabilizatör Anti Yalpalama Kaplini",
        //   "• KNOTT Premium Amortisör ",
        //   "• KNOTT Ön Destek Tekeri 150 kg ",
        //   "• KNOTT Destek Ayakları 4 Adet ",
        //   "• KNOTT Şasi Model 2 (4774 mm-1350 kg) ",
        // ],
        images: {
          lowQuality: [
            `${process.env.REACT_APP_BACKEND_STATIC}/images/models/common/exterior-design/low-quality/chassis-1.jpg`,
          ],
          highQuality: [
            `${process.env.REACT_APP_BACKEND_STATIC}/images/models/common/exterior-design/high-quality/chassis-1.jpg`,
          ],
        },
      },
      "rubber-rim": {
        // secondaryTexts: ["• V175/65R14/185/65R14C)"],
        images: {
          lowQuality: [
            `${process.env.REACT_APP_BACKEND_STATIC}/images/models/common/exterior-design/low-quality/dis-3.jpg`,
          ],
          highQuality: [
            `${process.env.REACT_APP_BACKEND_STATIC}/images/models/common/exterior-design/high-quality/dis-3.jpg`,
          ],
        },
      },
    },
  },
  "doga-350": {
    name: "Doğa 350",
    heroImages: {
      lg: {
        lowQuality: `${process.env.REACT_APP_BACKEND_STATIC}/images/models/doga-350/hero/low-quality/doga-350-hero-desktop.jpg`,
        highQuality: `${process.env.REACT_APP_BACKEND_STATIC}/images/models/doga-350/hero/high-quality/doga-350-hero-desktop.jpg`,
      },
      md: {
        lowQuality: `${process.env.REACT_APP_BACKEND_STATIC}/images/models/doga-350/hero/low-quality/doga-350-hero-900.jpg`,
        highQuality: `${process.env.REACT_APP_BACKEND_STATIC}/images/models/doga-350/hero/high-quality/doga-350-hero-900.jpg`,
      },
      sm: {
        lowQuality: `${process.env.REACT_APP_BACKEND_STATIC}/images/models/doga-350/hero/low-quality/doga-350-hero-600.jpg`,
        highQuality: `${process.env.REACT_APP_BACKEND_STATIC}/images/models/doga-350/hero/high-quality/doga-350-hero-600.jpg`,
      },
    },
    modelDimensions: {
      // secondaryTexts: [
      //   "• Toplam uzunluk: 4600mm",
      //   "• Toplam genişlik: 2120mm",
      //   "• Toplam yükseklik: 2475mm",
      //   "• Kabin uzunluğu: 3450mm",
      //   "• İç yükseklik: 1960mm",
      //   "• İç genişlik: 2040mm",
      // ],
      images: {
        lowQuality: [
          `${process.env.REACT_APP_BACKEND_STATIC}/images/models/doga-350/exterior-design/low-quality/doga-350_dis-1.jpg`,
        ],
        highQuality: [
          `${process.env.REACT_APP_BACKEND_STATIC}/images/models/doga-350/exterior-design/high-quality/doga-350_dis-1.jpg`,
        ],
      },
    },
    layouts: {
      primaryTexts: ["Plan 1", "Plan 2"],
      secondaryTexts: [
        ["Yatak Sayısı: 2", "Yatak Kapasitesi (Kişi): 2-3"],
        ["Yatak Sayısı: 3", "Yatak Kapasitesi (Kişi): 3-4"],
      ],

      images: {
        highQuality: [
          `${process.env.REACT_APP_BACKEND_STATIC}/images/models/doga-350/layout/doga-model-1.svg`,
          `${process.env.REACT_APP_BACKEND_STATIC}/images/models/doga-350/layout/doga-model-2.svg`,
        ],
      },
    },
    equipmentDetails: {
      furniture: {
        // secondaryTexts: [
        //   "• VENNI, NOPILPAN Laminant Baskı Marin Kavak Kontraplak",
        //   "• Gizli Bağlantı Sistemli Vidalama  ",
        //   "• HAFELE Gizli Kilit Sistemli Kapaklar  ",
        //   "• BALDACCI Bas Çek Kilit ",
        //   "• BERHIMI 12 V 85 L Buzdolabı ",
        //   "• CAN Ocak Ve Katlanır Musluk   ",
        //   "• CAN Kombinasyon Tek Gözlü Ocak ",
        //   "• LIPPERT 360 Derece Masa Ayağı ",
        //   "• Kapı Sinekliği  ",
        // ],
        images: {
          lowQuality: [
            `${process.env.REACT_APP_BACKEND_STATIC}/images/models/doga-350/interior-design/low-quality/doga-350_yasam-alani-6.jpg`,
            `${process.env.REACT_APP_BACKEND_STATIC}/images/models/doga-350/interior-design/low-quality/doga-350_yasam-alani-1.jpg`,
            `${process.env.REACT_APP_BACKEND_STATIC}/images/models/doga-350/interior-design/low-quality/doga-350_mutfak-4.jpg`,
            `${process.env.REACT_APP_BACKEND_STATIC}/images/models/doga-350/interior-design/low-quality/doga-350_mutfak-7.jpg`,
          ],
          highQuality: [
            `${process.env.REACT_APP_BACKEND_STATIC}/images/models/doga-350/interior-design/high-quality/doga-350_yasam-alani-6.jpg`,
            `${process.env.REACT_APP_BACKEND_STATIC}/images/models/doga-350/interior-design/high-quality/doga-350_yasam-alani-1.jpg`,
            `${process.env.REACT_APP_BACKEND_STATIC}/images/models/doga-350/interior-design/high-quality/doga-350_mutfak-4.jpg`,
            `${process.env.REACT_APP_BACKEND_STATIC}/images/models/doga-350/interior-design/high-quality/doga-350_mutfak-7.jpg`,
          ],
        },
      },
      bathroom: {
        // secondaryTexts: [
        //   "• Portatif / Kasetli Tuvalet   ",
        //   "• Spiralli Tetik Başlıklı Musluk  ",
        //   "• Geniş Kullanımlı Banyo Dolapları ",
        //   "• Duş Perdesi   ",
        //   "• 28x28 Fanlı Banyo Tavan Heki  ",
        // ],
        images: {
          lowQuality: [
            `${process.env.REACT_APP_BACKEND_STATIC}/images/models/doga-350/interior-design/low-quality/doga-350_banyo-1.jpg`,
            `${process.env.REACT_APP_BACKEND_STATIC}/images/models/doga-350/interior-design/low-quality/doga-350_banyo-2.jpg`,
          ],
          highQuality: [
            `${process.env.REACT_APP_BACKEND_STATIC}/images/models/doga-350/interior-design/high-quality/doga-350_banyo-1.jpg`,
            `${process.env.REACT_APP_BACKEND_STATIC}/images/models/doga-350/interior-design/high-quality/doga-350_banyo-2.jpg`,
          ],
        },
      },
      lighting: {
        // secondaryTexts: [
        //   "• Dolap Üstü 12 V Spot Aydınlatma  ",
        //   "• 12 V Kabin içi Aydınlatma Spotları ",
        //   "• USB Prizler  ",
        // ],
        images: {
          lowQuality: [
            `${process.env.REACT_APP_BACKEND_STATIC}/images/models/doga-350/interior-design/low-quality/doga-350_mutfak-4.jpg`,
            `${process.env.REACT_APP_BACKEND_STATIC}/images/models/doga-350/interior-design/low-quality/doga-350_banyo-2.jpg`,
          ],
          highQuality: [
            `${process.env.REACT_APP_BACKEND_STATIC}/images/models/doga-350/interior-design/high-quality/doga-350_mutfak-4.jpg`,
            `${process.env.REACT_APP_BACKEND_STATIC}/images/models/doga-350/interior-design/high-quality/doga-350_banyo-2.jpg`,
          ],
        },
      },
      "control-panel": {
        // secondaryTexts: ["• IOT Kaan Karavan Otomasyonu ", "• HONOR Tablet  "],
        images: {
          lowQuality: [
            `${process.env.REACT_APP_BACKEND_STATIC}/images/models/common/interior-design/low-quality/kontrol-paneli-1.jpg`,
          ],
          highQuality: [
            `${process.env.REACT_APP_BACKEND_STATIC}/images/models/common/interior-design/high-quality/kontrol-paneli-1.jpg`,
          ],
        },
      },
      "gas-system": {
        // secondaryTexts: [
        //   "• Truma Gaz Vanaları   ",
        //   "• Bakır Gaz Tesisatı  ",
        //   "• Tüp Tutucu  ",
        // ],
        images: {
          lowQuality: [
            `${process.env.REACT_APP_BACKEND_STATIC}/images/models/common/exterior-design/low-quality/gas-system-1.jpg`,
          ],
          highQuality: [
            `${process.env.REACT_APP_BACKEND_STATIC}/images/models/common/exterior-design/high-quality/gas-system-1.jpg`,
          ],
        },
      },
      "window-system": {
        // secondaryTexts: ["• BERHIMI Window Vision Pencere Sistemi "],
        images: {
          lowQuality: [
            `${process.env.REACT_APP_BACKEND_STATIC}/images/models/doga-350/interior-design/low-quality/doga-350_yasam-alani-6.jpg`,
          ],
          highQuality: [
            `${process.env.REACT_APP_BACKEND_STATIC}/images/models/doga-350/interior-design/high-quality/doga-350_yasam-alani-6.jpg`,
          ],
        },
      },
      "solar-energy-system": {
        // secondaryTexts: [
        //   "• 1 Adet 205 Watt Halfcut Güneş Paneli  ",
        //   "• NK1000-12 Apex Tam Sinüs invertör  ",
        //   "• ELECTROZIRVE ML2430 30 A Solar Charge Kontrol  ",
        //   "• ELECTROZIRVE 12,8 V 100 Ah Lityum Akü (LIFEPO4)  ",
        //   "• 10 A Transfer Switch  ",
        //   "• MEANWELL NPB360-12 20 A Akü Şarj Cihazı  ",
        // ],
        images: {
          lowQuality: [
            `${process.env.REACT_APP_BACKEND_STATIC}/images/models/doga-350/exterior-design/low-quality/doga-350_dis-14.jpg`,
          ],
          highQuality: [
            `${process.env.REACT_APP_BACKEND_STATIC}/images/models/doga-350/exterior-design/high-quality/doga-350_dis-14.jpg`,
          ],
        },
      },
      "heating-system": {
        // secondaryTexts: [
        //   "• Trumatic S3004 Gazlı Soba (Titanyum Gri) ",
        //   "• Truma Therme (220 V)  ",
        // ],
        images: {
          lowQuality: [
            `${process.env.REACT_APP_BACKEND_STATIC}/images/models/common/interior-design/low-quality/kontrol-paneli-1.jpg`,
          ],
          highQuality: [
            `${process.env.REACT_APP_BACKEND_STATIC}/images/models/common/interior-design/high-quality/kontrol-paneli-1.jpg`,
          ],
        },
      },
      "water-tank-and-plumming": {
        // secondaryTexts: [
        //   "• FIAMMA 70 L Temiz Su Deposu  ",
        //   "• Soğuk Sıcak Su Hortumu  ",
        //   "• Plastik Geçme Bağlantı Aparatları   ",
        //   "• PPRC Kirli Su Tesisatı (25 mm)  ",
        // ],
        images: {
          lowQuality: [
            `${process.env.REACT_APP_BACKEND_STATIC}/images/models/bulut-410/interior-design/low-quality/bulut-410_mutfak-4.jpg`,
          ],
          highQuality: [
            `${process.env.REACT_APP_BACKEND_STATIC}/images/models/bulut-410/interior-design/high-quality/bulut-410_mutfak-4.jpg`,
          ],
        },
      },
      "exterior-equipments": {
        // secondaryTexts: [
        //   "• Tavan Havalandırma Heki   ",
        //   "• Elektrik Girişi (Beyaz) ",
        //   "• Kilitli Su Dolum Ağzı (Beyaz) ",
        //   "• Tetikli Dış Duş (Beyaz ) ",
        //   "• JOKON WW250 Tente Ortam Işığı  ",
        //   "• HARTAL Servis Kapıları ",
        //   "• HARTAL Karavan Kapısı    ",
        //   "• Ön Arka Tampon Özel Tasarım Polyester Döküm ",
        //   "• JOKON Aydınlatma Grubu  ",
        // ],
        images: {
          lowQuality: [
            `${process.env.REACT_APP_BACKEND_STATIC}/images/models/common/exterior-design/low-quality/dis-1.jpg`,
            `${process.env.REACT_APP_BACKEND_STATIC}/images/models/common/exterior-design/low-quality/dis-9.jpg`,
          ],
          highQuality: [
            `${process.env.REACT_APP_BACKEND_STATIC}/images/models/common/exterior-design/high-quality/dis-1.jpg`,
            `${process.env.REACT_APP_BACKEND_STATIC}/images/models/common/exterior-design/high-quality/dis-9.jpg`,
          ],
        },
      },
      body: {
        // secondaryTexts: [
        //   "• Vakum Press Yöntemiyle Uygun Basınç Ve Laminasyon Tekniği ile CTP Panel Üretimi (High Gloss, Gofrajlı)  ",
        //   "• Yan Duvarlar: 33 mm, Tavan: 33 mm, Taban: 41 mm  ",
        //   "• iç Yüzeyler Anti Bakteriyel Kumaş Kaplama ",
        // ],
        images: {
          lowQuality: [
            `${process.env.REACT_APP_BACKEND_STATIC}/images/models/doga-350/exterior-design/low-quality/doga-350_dis-5.jpg`,
          ],
          highQuality: [
            `${process.env.REACT_APP_BACKEND_STATIC}/images/models/doga-350/exterior-design/high-quality/doga-350_dis-5.jpg`,
          ],
        },
      },
      chassis: {
        // secondaryTexts: [
        //   "• KNOTT 1050 kg Torsiyonlu Aks Frenli Dingil 1400mm  ",
        //   "• KNOTT Stabilizatör Anti Yalpalama Kaplini   ",
        //   "• KNOTT Premium Amortisör  ",
        //   "• KNOTT Ön Destek Tekeri 150 kg   ",
        //   "• KNOTT Destek Ayakları 4 Adet ",
        //   "• KNOTT Şasi Model 2 (4240 mm-1050 kg)  ",
        // ],
        images: {
          lowQuality: [
            `${process.env.REACT_APP_BACKEND_STATIC}/images/models/common/exterior-design/low-quality/chassis-1.jpg`,
          ],
          highQuality: [
            `${process.env.REACT_APP_BACKEND_STATIC}/images/models/common/exterior-design/high-quality/chassis-1.jpg`,
          ],
        },
      },
      "rubber-rim": {
        // secondaryTexts: ["• 175/65R14/185/65R14C"],
        images: {
          lowQuality: [
            `${process.env.REACT_APP_BACKEND_STATIC}/images/models/common/exterior-design/low-quality/dis-3.jpg`,
          ],
          highQuality: [
            `${process.env.REACT_APP_BACKEND_STATIC}/images/models/common/exterior-design/high-quality/dis-3.jpg`,
          ],
        },
      },
    },
  },
};
