import "./about-us.css";
import { useState, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import PageTitleSection from "../../components/page-title-section";
import ContentList from "../../components/content-list/content-list";
import WhoAreWeSection from "../../components/who-are-we-section";
import NavCard from "../../components/nav-card";
import TitleDescriptionContent from "../../components/title-description-content";
import ImageWithText from "../../components/image-with-text";
import PartnerCard from "../../components/partner-card";
import { cacheImages, isImagesInCache } from "../../utils/cache-images";

const AboutUs = () => {
  const { t: translate } = useTranslation();
  const pageTitles = {
    "who-are-we": {},
    "why-mops-caravan": {},
    "our-partners": {},
    "contact-us": {},
  };
  const [pageTitleImage, setPageTitleImage] = useState(undefined);
  const [whoWeAreImage, setWhoWeAreImage] = useState(undefined);
  const [whyMopscaravanImage, setWhyMopscaravanImage] = useState(undefined);
  const [partnerLogos, setPartnerLogos] = useState(undefined);
  const [whatsappIllustration, setWhatsappIllustration] = useState(undefined);
  const [emailIllustration, setEmailIllustration] = useState(undefined);
  const [
    contentListhorizontalPositionRefId,
    setContentListhorizontalPositionRefId,
  ] = useState(null);

  const checkContentListhorizontalPositionRefId = useCallback(() => {
    if (window.matchMedia("(max-width: 1120px)").matches)
      setContentListhorizontalPositionRefId("appbar-nav-item-pricing");
    else setContentListhorizontalPositionRefId("appbar-nav-item-pricing");
  }, []);

  const callPageTitleImage = useCallback(async () => {
    const pageTitleImage = `/images/high-quality/header.svg`;
    await cacheImages([pageTitleImage]);
    setPageTitleImage(pageTitleImage);
  }, []);

  const callWhoWeAreImage = useCallback(async () => {
    const lowQualityWhoWeAreImage = `${process.env.REACT_APP_BACKEND_STATIC}/images/models/bulut-410/exterior-design/low-quality/bulut-410_dis-3.jpg`;
    const highQualityWhoWeAreImage = `${process.env.REACT_APP_BACKEND_STATIC}/images/models/bulut-410/exterior-design/high-quality/bulut-410_dis-3.jpg`;
    if (
      !isImagesInCache([lowQualityWhoWeAreImage]) &&
      !isImagesInCache([highQualityWhoWeAreImage])
    )
      setWhoWeAreImage(lowQualityWhoWeAreImage);
    if (!isImagesInCache([highQualityWhoWeAreImage])) {
      await cacheImages([highQualityWhoWeAreImage]);
    }
    setWhoWeAreImage(highQualityWhoWeAreImage);
  }, []);

  const callWhyMopscaravanImage = useCallback(async () => {
    const lowQualityWhyMopscaravanImage = `${process.env.REACT_APP_BACKEND_STATIC}/images/models/bulut-410/exterior-design/low-quality/bulut-410_dis-2.jpg`;
    const highQualityWhyMopscaravanImage = `${process.env.REACT_APP_BACKEND_STATIC}/images/models/bulut-410/exterior-design/high-quality/bulut-410_dis-2.jpg`;
    if (
      !isImagesInCache([lowQualityWhyMopscaravanImage]) &&
      !isImagesInCache([highQualityWhyMopscaravanImage])
    )
      setWhyMopscaravanImage(lowQualityWhyMopscaravanImage);
    if (!isImagesInCache([highQualityWhyMopscaravanImage])) {
      await cacheImages([highQualityWhyMopscaravanImage]);
    }
    setWhyMopscaravanImage(highQualityWhyMopscaravanImage);
  }, []);

  const callPartnerLogos = useCallback(async () => {
    const partnerLogos = [
      "/logos/truma.svg",
      "/logos/can.svg",
      "/logos/mpk.svg",
      "/logos/hartal.svg",
      "/logos/thetford.svg",
      "/logos/berhimi.svg",
      "/logos/knott.svg",
      "/logos/alko.svg",
      "/logos/fiamma.svg",
      "/logos/aspock.svg",
      "/logos/elektrozirve.svg",
    ];
    if (!isImagesInCache([partnerLogos])) await cacheImages([partnerLogos]);
    setPartnerLogos(partnerLogos);
  }, []);

  const callContactIllustrations = useCallback(async () => {
    const whatsappIllustration =
      "/images/high-quality/whatsapp-illustration.png";
    const emailIllustration = "/images/high-quality/email-illustration.png";
    if (!isImagesInCache([whatsappIllustration, emailIllustration]))
      await cacheImages([whatsappIllustration, emailIllustration]);
    setWhatsappIllustration(whatsappIllustration);
    setEmailIllustration(emailIllustration);
  }, []);

  const setAllImages = async () => {
    await callPageTitleImage();
    await callWhoWeAreImage();
    await callWhyMopscaravanImage();
    await callPartnerLogos();
    await callContactIllustrations();
  };

  useEffect(() => {
    setAllImages();
  }, []);

  useEffect(() => {
    checkContentListhorizontalPositionRefId();
    window.addEventListener("resize", checkContentListhorizontalPositionRefId);
  }, [checkContentListhorizontalPositionRefId]);
  let containerClassesNarrow =
    "container-narrow container-narrow-md container-narrow-sm padding-block-64 padding-block-48-md-only padding-block-32-sm-only";

  return (
    <section id="about-us" className="about-us | background-color-primary-1000">
      <PageTitleSection
        id="about-us-page-title-section"
        className="padding-top-after-appbar"
        title={translate("about-us")}
        pageTitleImage={pageTitleImage}
      />
      <ContentList
        classes="padding-top-80 padding-top-64-md-only padding-top-48-sm-only"
        pageTitles={pageTitles}
        pageContentId="about-us-content"
        horizontalPositionRefId={contentListhorizontalPositionRefId}
        verticalPositionRefId="appbar"
      />
      <div id="about-us-content" className="about-us-content">
        <section
          className="prologue-section | container-narrow container-narrow-md container-narrow-sm
          padding-top-80 padding-top-64-md-only padding-top-48-sm-only"
        >
          {/* <p className="about-us-content-prologue | h4-font h5-font-md-only padding-bottom-64">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam
            tempor augue a libero porttitor varius. Fusce euismod massa eu
            accumsan ultricies.
          </p> */}
          <img src="/images/high-quality/dotted-road.svg" alt="fancy divider" />
        </section>
        <WhoAreWeSection
          id="who-are-we"
          containerClasses={containerClassesNarrow}
          image={whoWeAreImage}
          text={translate("who-we-are-text", { returnObjects: true })}
        />

        <TitleDescriptionContent
          id="why-mops-caravan"
          classes="background-color-primary-800"
          containerClasses={containerClassesNarrow}
          title={translate("why-mops-caravan")}
          description={translate("why-mopscaravan-text", {
            returnObjects: true,
          })}
        >
          <img
            className="about-us-why-mops-caravan-section-image | box-shadow-sm"
            src={whyMopscaravanImage}
            alt="who we are"
          />
          {/* <div className="about-us-why-mops-caravan-section-small-images-wrapper | margin-top-40">
            <ImageWithText
              classes="about-us-why-mops-caravan-section-image-with-text"
              image="/images/stock-caravan.png"
              primaryText="Bir Başlık"
              secondaryText="Lorem ipsum dolor sit amet asdkljas kdjasdklj asdj asdjasdj jasdkljasdklj askasd kljaskdljjjkasdjkasjd "
            />
            <ImageWithText
              classes="about-us-why-mops-caravan-section-image-with-text"
              image="/images/stock-caravan.png"
              primaryText="Bir Başlık"
              secondaryText="Lorem ipsum dolor sit amet"
            />
            <ImageWithText
              classes="about-us-why-mops-caravan-section-image-with-text"
              image="/images/stock-caravan.png"
              primaryText="Bir Başlık"
              secondaryText="Lorem ipsum dolor sit amet"
            />
          </div> */}
        </TitleDescriptionContent>
        <TitleDescriptionContent
          id="our-partners"
          classes="background-color-gray-800"
          containerClasses={containerClassesNarrow}
          title={translate("our-partners")}
          // description={[
          //   "Nulla eu lorem ut nulla vulputate vulputate a cursus lorem. Proin quis elit in augue rhoncus interdum. Aliquam ipsum mauris, tincidunt ac blandit eu, efficitur sed nulla. Nam eu tristique lorem. Cras at dolor eget urna scelerisque gravida. Nam elementum enim porttitor, feugiat tortor et, porta sapien.",
          // ]}
        >
          <div className="about-us-content-our-partners-images">
            {partnerLogos &&
              partnerLogos.map((partnerLogo) => (
                <div key={partnerLogo}>
                  <PartnerCard image={partnerLogo} />
                </div>
              ))}
          </div>
        </TitleDescriptionContent>
        <TitleDescriptionContent
          id="contact-us"
          classes="background-color-primary-900"
          containerClasses={containerClassesNarrow}
          title={translate("contact-us")}
          // description={[
          //   "Lorem ipsum falan lorem ipsum falan lorem ipsum falan lorem ipsum falan lorem ipsum falan lorem ipsum falan lorem ipsum falan",
          // ]}
        >
          <div className="home-contact-us-section-navcards-wrapper">
            <NavCard
              title={translate("whatsapp")}
              backgroundImage={whatsappIllustration}
              classes="home-contact-us-section-navcard"
              to="//api.whatsapp.com/send?phone=905059277948&text=Merhaba, karavanlarınız hakkında bilgi almak istiyorum."
            />
            <NavCard
              title={translate("email")}
              backgroundImage={emailIllustration}
              classes="home-contact-us-section-navcard"
              to="/contact#contact-us-form"
            />
          </div>
        </TitleDescriptionContent>
      </div>
    </section>
  );
};

export default AboutUs;
