import "./features-section.css";
import { useTranslation } from "react-i18next";

const FeaturesSection = (props) => {
  const { t: translate } = useTranslation();
  const { id, classes, containerClasses, title, text, children } = props;

  return (
    <section id={id} className={`features-section ${classes}`}>
      <div className={containerClasses}>
        <p className="features-section-title | h3-font">{title}</p>
        <p className="features-section-text | h5-font h6-font-sm-only margin-top-40 margin-top-32-md-only margin-top-24-sm-only">
          {text}
        </p>
        <div className="features-section-content">{children}</div>
      </div>
    </section>
  );
};

export default FeaturesSection;
