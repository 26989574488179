import React, { useEffect } from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./css/index.css";
import "./i18n/config";
import Layout from "./pages/layout";
import Home from "./pages/home";
import Blog from "./pages/blog";
import Article from "./pages/article";
import Contact from "./pages/contact";
import NotFound from "./pages/not-found";
import AboutUs from "./pages/about-us";
import Model from "./pages/model";
import Gallery from "./pages/gallery";
import PriceList from "./pages/price-list";
import ScrollToTop from "./utils/scroll-to-top";
import Models from "./pages/models/models";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="about-us" element={<AboutUs />} />
          <Route path="models" element={<Models />} />
          <Route path="model" element={<Model />} />
          <Route path="gallery" element={<Gallery />} />
          <Route path="pricing" element={<PriceList />} />
          {/* <Route path="blog" element={<Blog />} /> */}
          <Route path="blog/:id" element={<Article />} />
          <Route path="contact" element={<Contact />} />
          <Route path="*" element={<NotFound />} />
        </Route>
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);
