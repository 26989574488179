import "./hero.css";
import { useTranslation } from "react-i18next";
import HeroCard from "../hero-card";
import NavCard from "../nav-card";
import { useState, useEffect, useCallback } from "react";
import { modelDetails } from "../../definitions/model-details";
import { cacheImages } from "../../utils/cache-images";

const longLogo = "/logos/mops_yesil-logo.svg";
const shortLogo = "/logos/mops_brandmark-renkli.svg";

const Hero = (props) => {
  const { t: translate } = useTranslation();
  const { containerClasses, heroBackgroundImage } = props;
  const [logo, setLogo] = useState(longLogo);

  const setCorrectLogo = useCallback(() => {
    if (window.matchMedia("(max-width: 600px)").matches) setLogo(undefined);
    else setLogo(longLogo);
  }, []);

  // const setBackgroundImage = useCallback(async () => {
  //   const heroSection = document.querySelector("#hero-section");
  //   let lowQualityHeroImage, highQualityHeroImage;
  //   if (heroSection) {
  //     if (window.matchMedia("(max-width: 600px)").matches) {
  //       lowQualityHeroImage =
  //         modelDetails["bulut-410"].heroImages.sm.lowQuality;
  //       highQualityHeroImage =
  //         modelDetails["bulut-410"].heroImages.sm.highQuality;
  //     } else if (window.matchMedia("(max-width: 1140px)").matches) {
  //       lowQualityHeroImage =
  //         modelDetails["bulut-410"].heroImages.md.lowQuality;
  //       highQualityHeroImage =
  //         modelDetails["bulut-410"].heroImages.md.highQuality;
  //     } else {
  //       lowQualityHeroImage =
  //         modelDetails["bulut-410"].heroImages.lg.lowQuality;
  //       highQualityHeroImage =
  //         modelDetails["bulut-410"].heroImages.lg.highQuality;
  //     }
  //     heroSection.style.setProperty(
  //       "--hero-section-image-url",
  //       `url(${lowQualityHeroImage})`
  //     );
  //     console.log("LOW quality hero image set");
  //     await cacheImages([highQualityHeroImage]);
  //     console.log("HIGH quality hero image set");
  //     heroSection.style.setProperty(
  //       "--hero-section-image-url",
  //       `url(${highQualityHeroImage})`
  //     );
  //   }
  // }, []);

  useEffect(() => {
    setCorrectLogo();
    window.addEventListener("resize", setCorrectLogo);
    // setBackgroundImage();
    // window.addEventListener("resize", setBackgroundImage);
  }, [
    setCorrectLogo,
    // , setBackgroundImage
  ]);

  useEffect(() => {
    const heroSection = document.querySelector("#hero-section");
    if (heroSection)
      heroSection.style.setProperty(
        "--hero-section-image-url",
        `url(${heroBackgroundImage})`
      );
  }, [heroBackgroundImage]);

  return (
    <section id="hero-section" className="hero-section">
      <div className="padding-top-after-appbar hero-content-wrapper-container">
        <div className={`hero-content-wrapper | ${containerClasses}`}>
          <div>
            {logo && (
              <div className="hero-logo-container">
                <img className="hero-logo" src={logo} />
              </div>
            )}

            {/* <HeroCard classes="margin-top-24 background-color-gray-60" /> */}
          </div>
          {/* <div className="hero-navcards-wrapper | margin-top-64">
            <NavCard
              title={translate("models")}
              backgroundImage="/images/trailer-illustration.png"
              classes="hero-navcard"
              to="/models"
            />
            <NavCard
              title={translate("pricing")}
              backgroundImage="/images/pricing-illustration.png"
              classes="hero-navcard"
              to="/pricing"
            />
            <NavCard
              title={translate("about-us")}
              backgroundImage="/images/about-us-illustration.png"
              classes="hero-navcard"
              to="/about-us"
            />
          </div> */}
        </div>
      </div>
    </section>
  );
};

export default Hero;
