import "./simple-table.css";

const SimpleTable = (props) => {
  const { id, titles, rows, classes } = props;
  return (
    <ul id={id} className={`simple-table ${classes}`}>
      <li className="simple-table-titles | h5-font h6-font-sm-only padding-bottom-12">
        {titles.map((title, index) => (
          <span key={title} className="simple-table-title">
            {title}
          </span>
        ))}
      </li>
      {rows.map((row, index) => (
        <li
          key={"simple-table-row" + index}
          className="simple-table-row | h6-font b2-font-sm-only padding-block-12"
        >
          {row.map((column, index) => (
            <span key={column} className="simple-table-data-column">
              {column}
            </span>
          ))}
        </li>
      ))}
    </ul>
  );
};

export default SimpleTable;
