import "./price-list.css";
import { useTranslation } from "react-i18next";
import PageTitleSection from "../../components/page-title-section";
import PriceListCard from "../../components/price-list-card";
import AdvancedButton from "../../components/advanced-button";
import { modelKeys } from "../../definitions/models";
import { useState, useEffect, useCallback } from "react";
import { cacheImages, isImagesInCache } from "../../utils/cache-images";

const PriceList = () => {
  const { t: translate } = useTranslation();
  const [pageTitleImage, setPageTitleImage] = useState(undefined);

  const callPageTitleImage = useCallback(async () => {
    const pageTitleImage = `/images/high-quality/header.svg`;
    if (!isImagesInCache([pageTitleImage])) await cacheImages([pageTitleImage]);
    setPageTitleImage(pageTitleImage);
  }, []);

  const setAllImages = async () => {
    await callPageTitleImage();
  };

  useEffect(() => {
    setAllImages();
  }, []);

  const containerClasses =
    "container padding-block-64 padding-block-48-md-only padding-block-32-sm-only";
  return (
    <section
      id="price-list"
      className="price-list | background-color-primary-1000"
    >
      <PageTitleSection
        id="price-list-page-title-section"
        className="padding-top-after-appbar"
        title={translate("pricing")}
        pageTitleImage={pageTitleImage}
      />
      <div className={containerClasses}>
        <div id="price-list-content" className="price-list-content">
          <div className={`price-list-cards`}>
            <PriceListCard
              classes="background-color-primary-850"
              image={
                process.env.REACT_APP_BACKEND_STATIC +
                "/images/models/bulut-410/layout/bulut-model-1.svg"
              }
              primaryTitles={["Bulut 410", "Plan 1"]}
              secondaryTitle={translate("trailer")}
              beds={3}
              people="3-4"
              price="15.400€ + KDV"
              modelKey={modelKeys["bulut-410"]}
              buttons={[
                <AdvancedButton
                  classes="background-color-primary-650 box-shadow-sm"
                  icon="/icons/arrow-right.svg"
                  primaryText={translate("details")}
                  // secondaryText={translate("trailer") + " Model 1"}
                  to={`/model?model=${modelKeys["bulut-410"]}#layouts`}
                />,
                // <AdvancedButton
                //   classes="background-color-primary-650 box-shadow-sm"
                //   icon="/icons/360.svg"
                //   primaryText={translate("view-360")}
                //   secondaryText={translate("trailer") + " Model 1"}
                //   to=""
                // />,
              ]}
            />
            <PriceListCard
              classes="background-color-primary-850 "
              image={
                process.env.REACT_APP_BACKEND_STATIC +
                "/images/models/bulut-410/layout/bulut-model-2.svg"
              }
              primaryTitles={["Bulut 410", "Plan 2"]}
              secondaryTitle={translate("trailer")}
              beds={2}
              people="3-4"
              price="15.400€ + KDV"
              modelKey={modelKeys["bulut-410"]}
              buttons={[
                <AdvancedButton
                  classes="background-color-primary-650 box-shadow-sm"
                  icon="/icons/arrow-right.svg"
                  primaryText={translate("details")}
                  // secondaryText={translate("trailer") + " Model 1"}
                  to={`/model?model=${modelKeys["bulut-410"]}#layouts`}
                />,
                // <AdvancedButton
                //   classes="background-color-primary-650"
                //   icon="/icons/360.svg"
                //   primaryText={translate("view-360")}
                //   secondaryText={translate("trailer") + " Model 1"}
                // />,
              ]}
            />
            <PriceListCard
              classes="background-color-primary-850"
              image={
                process.env.REACT_APP_BACKEND_STATIC +
                "/images/models/doga-350/layout/doga-model-1.svg"
              }
              primaryTitles={["Doğa 350", "Plan 1"]}
              secondaryTitle={translate("trailer")}
              beds={2}
              people="2-3"
              price="13.400€ + KDV"
              modelKey={modelKeys["doga-350"]}
              buttons={[
                <AdvancedButton
                  classes="background-color-primary-650 box-shadow-sm"
                  icon="/icons/arrow-right.svg"
                  primaryText={translate("details")}
                  // secondaryText={translate("trailer") + " Model 1"}
                  to={`/model?model=${modelKeys["doga-350"]}#layouts`}
                />,
                // <AdvancedButton
                //   classes="background-color-primary-650"
                //   icon="/icons/360.svg"
                //   primaryText={translate("view-360")}
                //   secondaryText={translate("trailer") + " Model 1"}
                // />,
              ]}
            />
            <PriceListCard
              classes="background-color-primary-850"
              image={
                process.env.REACT_APP_BACKEND_STATIC +
                "/images/models/doga-350/layout/doga-model-2.svg"
              }
              primaryTitles={["Doğa 350", "Plan 2"]}
              secondaryTitle={translate("trailer")}
              beds={3}
              people="3-4"
              price="13.400€ + KDV"
              modelKey={modelKeys["doga-350"]}
              buttons={[
                <AdvancedButton
                  classes="background-color-primary-650 box-shadow-sm"
                  icon="/icons/arrow-right.svg"
                  primaryText={translate("details")}
                  // secondaryText={translate("trailer") + " Model 1"}
                  to={`/model?model=${modelKeys["doga-350"]}#layouts`}
                />,
                // <AdvancedButton
                //   classes="background-color-primary-650"
                //   icon="/icons/360.svg"
                //   primaryText={translate("view-360")}
                //   secondaryText={translate("trailer") + " Model 1"}
                // />,
              ]}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default PriceList;
