import "./text-left-images-right.css";

const TextLeftImagesRight = (props) => {
  const { primaryText, secondaryTexts, images, classes } = props;
  return (
    <div className={`text-left-images-right ${classes}`}>
      {/* <div className="text-left-images-right-text | margin-top-48 margin-top-0-md-only"> */}
      <div className="text-left-images-right-text | margin-top-0-md-only">
        <p className="h5-font"> {primaryText} </p>
        <div>
          {secondaryTexts.map((secondaryText, index) => (
            <p
              key={`${secondaryText}-${index}`}
              className="b1-font b2-font-sm-only margin-top-4"
            >
              {secondaryText}
            </p>
          ))}
        </div>
      </div>
      <div
        className={
          images && images.length > 1
            ? "text-left-images-right-images-grid"
            : "text-left-images-right-image-container"
        }
      >
        {images &&
          images.length > 0 &&
          images.map((image, index) => (
            <img
              key={`text-left-images-right-image-${index}`}
              id={`text-left-images-right-image-${index}`}
              className="text-left-images-right-image | box-shadow-sm"
              src={image}
            />
          ))}
      </div>
    </div>
  );
};

export default TextLeftImagesRight;
