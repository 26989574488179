import "./images-top-texts-bottom.css";

const ImagesTopTextsBottom = (props) => {
  const { images, primaryTexts, secondaryTexts, buttons, classes } = props;
  const isAnyTextOrButton = primaryTexts || secondaryTexts || buttons;
  return (
    <div
      className={`images-top-texts-bottom-${
        images && images.length
      }-item ${classes}`}
      data-only-image={!isAnyTextOrButton}
    >
      {images &&
        images.map((image, index) => (
          <div
            key={`${
              isAnyTextOrButton
                ? "images-top-texts-bottom-item-grid"
                : "images-top-texts-bottom-item"
            }-${index}`}
            className={`${
              isAnyTextOrButton
                ? "images-top-texts-bottom-item-grid"
                : "images-top-texts-bottom-item"
            }`}
          >
            <img
              id={`images-top-texts-bottom-image-${index}`}
              className="images-top-texts-bottom-image"
              src={image || "/icons/mops-favicon.svg"}
            />
            {isAnyTextOrButton && (
              <div className="images-top-texts-bottom-texts-and-button | margin-top-24 margin-top-0-md-only">
                <div className="images-top-texts-bottom-texts">
                  {primaryTexts && (
                    <p className="h5-font"> {primaryTexts[index]} </p>
                  )}
                  <div className="margin-top-20 margin-top-16-md-only margin-top-12-sm-only">
                    {secondaryTexts &&
                      secondaryTexts[index].map((secondaryText, index) => (
                        <p
                          key={`${secondaryText}-${index}`}
                          className="b1-font b2-font-sm-only"
                        >
                          {secondaryText}
                        </p>
                      ))}
                  </div>
                </div>

                {buttons && (
                  <div className="images-top-texts-bottom-buttons | margin-top-28 margin-top-20-md-only margin-top-16-sm-only">
                    {buttons[index]}
                  </div>
                )}
              </div>
            )}
          </div>
        ))}
    </div>
  );
};

export default ImagesTopTextsBottom;
