import "./language-toggle-button.css";
import { useEffect, useState } from "react";

const LanguageToggleButton = (props) => {
  const { i18n, changeLanguage } = props;
  const [language, setLanguage] = useState(null);

  useEffect(() => {
    if (i18n.language.localeCompare("tr") === 0) setLanguage("en");
    else setLanguage("tr");
  }, [i18n.language]);

  return (
    <div
      id="language-toggle-button"
      className="background-color-primary-1000 text-color-white-87"
      onClick={() => changeLanguage(language)}
    >
      <img
        id="language-icon"
        src="/icons/language.svg"
        alt="Language toggle button"
      />
      <p className="h6-font b1-font-md-only b2-font-sm-only">
        {i18n.language.localeCompare("tr") === 0 ? "TR" : "EN"}
      </p>
    </div>
  );
};

export default LanguageToggleButton;
